/**
 * Mixin that creates a new stacking context.
 * see https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context
 */
html, body {
  height: 100%;
  color: rgba(0, 0, 0, 0.87);
  background: white;
  position: relative; }

body {
  margin: 0;
  padding: 0; }

[tabindex='-1']:focus {
  outline: none; }

.inset {
  padding: 10px; }

button.md-no-style {
  font-weight: normal;
  background-color: inherit;
  text-align: left;
  border: none;
  padding: 0;
  margin: 0; }

select,
button,
textarea,
input {
  vertical-align: baseline; }

input[type="reset"],
input[type="submit"],
html input[type="button"],
button {
  cursor: pointer;
  -webkit-appearance: button; }
  input[type="reset"][disabled],
  input[type="submit"][disabled],
  html input[type="button"][disabled],
  button[disabled] {
    cursor: default; }

textarea {
  vertical-align: top;
  overflow: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
  -webkit-box-sizing: content-box; }
  input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none; }

.md-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-transform: none;
  width: 1px; }

.md-shadow {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: inherit;
  pointer-events: none; }

.md-shadow-bottom-z-1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26); }

.md-shadow-bottom-z-2 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4); }

.md-shadow-animated.md-shadow {
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1); }

/*
 * A container inside of a rippling element (eg a button),
 * which contains all of the individual ripples
 */
.md-ripple-container {
  pointer-events: none;
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

/*
 * A container inside of a rippling element (eg a button),
 * which contains all of the individual ripples
 */
.md-ripple-container {
  pointer-events: none;
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.55s cubic-bezier(0.25, 0.8, 0.25, 1); }

.md-ripple {
  position: absolute;
  transform: translate(-50%, -50%) scale(0);
  transform-origin: 50% 50%;
  opacity: 0;
  border-radius: 50%; }
  .md-ripple.md-ripple-placed {
    transition: margin 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), border 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), width 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), height 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), opacity 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.9s cubic-bezier(0.25, 0.8, 0.25, 1); }
  .md-ripple.md-ripple-scaled {
    transform: translate(-50%, -50%) scale(1); }
  .md-ripple.md-ripple-active, .md-ripple.md-ripple-full, .md-ripple.md-ripple-visible {
    opacity: 0.20; }

.md-padding {
  padding: 8px; }

.md-margin {
  margin: 8px; }

.md-scroll-mask {
  position: absolute;
  background-color: transparent;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }
  .md-scroll-mask > .md-scroll-mask-bar {
    display: block;
    position: absolute;
    background-color: #fafafa;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 65;
    box-shadow: inset 0px 0px 1px rgba(0, 0, 0, 0.3); }

.md-no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

@media (min-width: 600px) {
  .md-padding {
    padding: 16px; } }

html, body {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/************
 * Headings
 ************/
.md-display-4 {
  font-size: 112px;
  font-weight: 300;
  letter-spacing: -0.010em;
  line-height: 112px; }

.md-display-3 {
  font-size: 56px;
  font-weight: 400;
  letter-spacing: -0.005em;
  line-height: 56px; }

.md-display-2 {
  font-size: 45px;
  font-weight: 400;
  line-height: 64px; }

.md-display-1 {
  font-size: 34px;
  font-weight: 400;
  line-height: 40px; }

.md-headline, .md-dialog md-dialog-title {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px; }

.md-title {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.005em; }

.md-subhead {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.010em;
  line-height: 24px; }

/************
 * Body Copy
 ************/
.md-body-1 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.010em;
  line-height: 20px; }

.md-body-2 {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.010em;
  line-height: 24px; }

.md-caption {
  font-size: 12px;
  letter-spacing: 0.020em; }

.md-button {
  letter-spacing: 0.010em; }

/************
 * Defaults
 ************/
button,
select,
html,
textarea,
input {
  font-family: RobotoDraft, Roboto, "Helvetica Neue", sans-serif; }

select,
button,
textarea,
input {
  font-size: 100%; }

/*
*
*  Responsive attributes
*
*  References:
*  1) https://scotch.io/tutorials/a-visual-guide-to-css3-flexbox-properties#flex
*  2) https://css-tricks.com/almanac/properties/f/flex/
*  3) https://css-tricks.com/snippets/css/a-guide-to-flexbox/
*  4) https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
*  5) http://godban.com.ua/projects/flexgrid
*
*/
@-moz-document url-prefix() {
  [layout-fill] {
    margin: 0;
    width: 100%;
    min-height: 100%;
    height: 100%; } }

/*
 *  Apply Mixins to create Layout/Flexbox styles
 *
 */
[flex-order] {
  order: 0; }

[flex-order="-20"] {
  order: -20; }

[flex-order="-19"] {
  order: -19; }

[flex-order="-18"] {
  order: -18; }

[flex-order="-17"] {
  order: -17; }

[flex-order="-16"] {
  order: -16; }

[flex-order="-15"] {
  order: -15; }

[flex-order="-14"] {
  order: -14; }

[flex-order="-13"] {
  order: -13; }

[flex-order="-12"] {
  order: -12; }

[flex-order="-11"] {
  order: -11; }

[flex-order="-10"] {
  order: -10; }

[flex-order="-9"] {
  order: -9; }

[flex-order="-8"] {
  order: -8; }

[flex-order="-7"] {
  order: -7; }

[flex-order="-6"] {
  order: -6; }

[flex-order="-5"] {
  order: -5; }

[flex-order="-4"] {
  order: -4; }

[flex-order="-3"] {
  order: -3; }

[flex-order="-2"] {
  order: -2; }

[flex-order="-1"] {
  order: -1; }

[flex-order="0"] {
  order: 0; }

[flex-order="1"] {
  order: 1; }

[flex-order="2"] {
  order: 2; }

[flex-order="3"] {
  order: 3; }

[flex-order="4"] {
  order: 4; }

[flex-order="5"] {
  order: 5; }

[flex-order="6"] {
  order: 6; }

[flex-order="7"] {
  order: 7; }

[flex-order="8"] {
  order: 8; }

[flex-order="9"] {
  order: 9; }

[flex-order="10"] {
  order: 10; }

[flex-order="11"] {
  order: 11; }

[flex-order="12"] {
  order: 12; }

[flex-order="13"] {
  order: 13; }

[flex-order="14"] {
  order: 14; }

[flex-order="15"] {
  order: 15; }

[flex-order="16"] {
  order: 16; }

[flex-order="17"] {
  order: 17; }

[flex-order="18"] {
  order: 18; }

[flex-order="19"] {
  order: 19; }

[flex-order="20"] {
  order: 20; }

[flex-offset="0"] {
  margin-left: 0%; }

[flex-offset="5"] {
  margin-left: 5%; }

[flex-offset="10"] {
  margin-left: 10%; }

[flex-offset="15"] {
  margin-left: 15%; }

[flex-offset="20"] {
  margin-left: 20%; }

[flex-offset="25"] {
  margin-left: 25%; }

[flex-offset="30"] {
  margin-left: 30%; }

[flex-offset="35"] {
  margin-left: 35%; }

[flex-offset="40"] {
  margin-left: 40%; }

[flex-offset="45"] {
  margin-left: 45%; }

[flex-offset="50"] {
  margin-left: 50%; }

[flex-offset="55"] {
  margin-left: 55%; }

[flex-offset="60"] {
  margin-left: 60%; }

[flex-offset="65"] {
  margin-left: 65%; }

[flex-offset="70"] {
  margin-left: 70%; }

[flex-offset="75"] {
  margin-left: 75%; }

[flex-offset="80"] {
  margin-left: 80%; }

[flex-offset="85"] {
  margin-left: 85%; }

[flex-offset="90"] {
  margin-left: 90%; }

[flex-offset="95"] {
  margin-left: 95%; }

[flex-offset="33"] {
  margin-left: calc(100% / 3); }

[flex-offset="66"] {
  margin-left: calc(200% / 3); }

[layout-align],
[layout-align="start stretch"] {
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch; }

[layout-align="start"],
[layout-align="start start"],
[layout-align="start center"],
[layout-align="start end"],
[layout-align="start stretch"] {
  justify-content: start; }

[layout-align="center"],
[layout-align="center start"],
[layout-align="center center"],
[layout-align="center end"],
[layout-align="center stretch"] {
  justify-content: center; }

[layout-align="end"],
[layout-align="end center"],
[layout-align="end start"],
[layout-align="end end"],
[layout-align="end stretch"] {
  justify-content: flex-end; }

[layout-align="space-around"],
[layout-align="space-around center"],
[layout-align="space-around start"],
[layout-align="space-around end"],
[layout-align="space-around stretch"] {
  justify-content: space-around; }

[layout-align="space-between"],
[layout-align="space-between center"],
[layout-align="space-between start"],
[layout-align="space-between end"],
[layout-align="space-between stretch"] {
  justify-content: space-between; }

[layout-align="start start"],
[layout-align="center start"],
[layout-align="end start"],
[layout-align="space-between start"],
[layout-align="space-around start"] {
  align-items: flex-start;
  align-content: flex-start; }

[layout-align="start center"],
[layout-align="center center"],
[layout-align="end center"],
[layout-align="space-between center"],
[layout-align="space-around center"] {
  align-items: center;
  align-content: center;
  max-width: 100%; }

[layout-align="start center"] > *,
[layout-align="center center"] > *,
[layout-align="end center"] > *,
[layout-align="space-between center"] > *,
[layout-align="space-around center"] > * {
  max-width: 100%;
  box-sizing: border-box; }

[layout-align="start end"],
[layout-align="center end"],
[layout-align="end end"],
[layout-align="space-between end"],
[layout-align="space-around end"] {
  align-items: flex-end;
  align-content: flex-end; }

[layout-align="start stretch"],
[layout-align="center stretch"],
[layout-align="end stretch"],
[layout-align="space-between stretch"],
[layout-align="space-around stretch"] {
  align-items: stretch;
  align-content: stretch; }

[flex] {
  flex: 1;
  box-sizing: border-box; }

@media screen\0 {
  [flex] {
    flex: 1 1 0%; } }

[flex-grow] {
  flex: 1 1 100%;
  box-sizing: border-box; }

[flex-initial] {
  flex: 0 1 auto;
  box-sizing: border-box; }

[flex-auto] {
  flex: 1 1 auto;
  box-sizing: border-box; }

[flex-none] {
  flex: 0 0 auto;
  box-sizing: border-box; }

[flex-noshrink] {
  flex: 1 0 auto;
  box-sizing: border-box; }

[flex-nogrow] {
  flex: 0 1 auto;
  box-sizing: border-box; }

[flex="0"] {
  flex: 1 1 0%;
  max-width: 0%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="0"],
[layout="row"] > [flex="0"] {
  flex: 1 1 0%;
  max-width: 0%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="0"],
[layout="column"] > [flex="0"] {
  flex: 1 1 0%;
  max-width: 100%;
  max-height: 0%;
  box-sizing: border-box; }

[flex="5"] {
  flex: 1 1 5%;
  max-width: 5%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="5"],
[layout="row"] > [flex="5"] {
  flex: 1 1 5%;
  max-width: 5%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="5"],
[layout="column"] > [flex="5"] {
  flex: 1 1 5%;
  max-width: 100%;
  max-height: 5%;
  box-sizing: border-box; }

[flex="10"] {
  flex: 1 1 10%;
  max-width: 10%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="10"],
[layout="row"] > [flex="10"] {
  flex: 1 1 10%;
  max-width: 10%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="10"],
[layout="column"] > [flex="10"] {
  flex: 1 1 10%;
  max-width: 100%;
  max-height: 10%;
  box-sizing: border-box; }

[flex="15"] {
  flex: 1 1 15%;
  max-width: 15%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="15"],
[layout="row"] > [flex="15"] {
  flex: 1 1 15%;
  max-width: 15%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="15"],
[layout="column"] > [flex="15"] {
  flex: 1 1 15%;
  max-width: 100%;
  max-height: 15%;
  box-sizing: border-box; }

[flex="20"] {
  flex: 1 1 20%;
  max-width: 20%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="20"],
[layout="row"] > [flex="20"] {
  flex: 1 1 20%;
  max-width: 20%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="20"],
[layout="column"] > [flex="20"] {
  flex: 1 1 20%;
  max-width: 100%;
  max-height: 20%;
  box-sizing: border-box; }

[flex="25"] {
  flex: 1 1 25%;
  max-width: 25%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="25"],
[layout="row"] > [flex="25"] {
  flex: 1 1 25%;
  max-width: 25%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="25"],
[layout="column"] > [flex="25"] {
  flex: 1 1 25%;
  max-width: 100%;
  max-height: 25%;
  box-sizing: border-box; }

[flex="30"] {
  flex: 1 1 30%;
  max-width: 30%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="30"],
[layout="row"] > [flex="30"] {
  flex: 1 1 30%;
  max-width: 30%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="30"],
[layout="column"] > [flex="30"] {
  flex: 1 1 30%;
  max-width: 100%;
  max-height: 30%;
  box-sizing: border-box; }

[flex="35"] {
  flex: 1 1 35%;
  max-width: 35%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="35"],
[layout="row"] > [flex="35"] {
  flex: 1 1 35%;
  max-width: 35%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="35"],
[layout="column"] > [flex="35"] {
  flex: 1 1 35%;
  max-width: 100%;
  max-height: 35%;
  box-sizing: border-box; }

[flex="40"] {
  flex: 1 1 40%;
  max-width: 40%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="40"],
[layout="row"] > [flex="40"] {
  flex: 1 1 40%;
  max-width: 40%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="40"],
[layout="column"] > [flex="40"] {
  flex: 1 1 40%;
  max-width: 100%;
  max-height: 40%;
  box-sizing: border-box; }

[flex="45"] {
  flex: 1 1 45%;
  max-width: 45%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="45"],
[layout="row"] > [flex="45"] {
  flex: 1 1 45%;
  max-width: 45%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="45"],
[layout="column"] > [flex="45"] {
  flex: 1 1 45%;
  max-width: 100%;
  max-height: 45%;
  box-sizing: border-box; }

[flex="50"] {
  flex: 1 1 50%;
  max-width: 50%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="50"],
[layout="row"] > [flex="50"] {
  flex: 1 1 50%;
  max-width: 50%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="50"],
[layout="column"] > [flex="50"] {
  flex: 1 1 50%;
  max-width: 100%;
  max-height: 50%;
  box-sizing: border-box; }

[flex="55"] {
  flex: 1 1 55%;
  max-width: 55%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="55"],
[layout="row"] > [flex="55"] {
  flex: 1 1 55%;
  max-width: 55%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="55"],
[layout="column"] > [flex="55"] {
  flex: 1 1 55%;
  max-width: 100%;
  max-height: 55%;
  box-sizing: border-box; }

[flex="60"] {
  flex: 1 1 60%;
  max-width: 60%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="60"],
[layout="row"] > [flex="60"] {
  flex: 1 1 60%;
  max-width: 60%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="60"],
[layout="column"] > [flex="60"] {
  flex: 1 1 60%;
  max-width: 100%;
  max-height: 60%;
  box-sizing: border-box; }

[flex="65"] {
  flex: 1 1 65%;
  max-width: 65%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="65"],
[layout="row"] > [flex="65"] {
  flex: 1 1 65%;
  max-width: 65%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="65"],
[layout="column"] > [flex="65"] {
  flex: 1 1 65%;
  max-width: 100%;
  max-height: 65%;
  box-sizing: border-box; }

[flex="70"] {
  flex: 1 1 70%;
  max-width: 70%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="70"],
[layout="row"] > [flex="70"] {
  flex: 1 1 70%;
  max-width: 70%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="70"],
[layout="column"] > [flex="70"] {
  flex: 1 1 70%;
  max-width: 100%;
  max-height: 70%;
  box-sizing: border-box; }

[flex="75"] {
  flex: 1 1 75%;
  max-width: 75%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="75"],
[layout="row"] > [flex="75"] {
  flex: 1 1 75%;
  max-width: 75%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="75"],
[layout="column"] > [flex="75"] {
  flex: 1 1 75%;
  max-width: 100%;
  max-height: 75%;
  box-sizing: border-box; }

[flex="80"] {
  flex: 1 1 80%;
  max-width: 80%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="80"],
[layout="row"] > [flex="80"] {
  flex: 1 1 80%;
  max-width: 80%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="80"],
[layout="column"] > [flex="80"] {
  flex: 1 1 80%;
  max-width: 100%;
  max-height: 80%;
  box-sizing: border-box; }

[flex="85"] {
  flex: 1 1 85%;
  max-width: 85%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="85"],
[layout="row"] > [flex="85"] {
  flex: 1 1 85%;
  max-width: 85%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="85"],
[layout="column"] > [flex="85"] {
  flex: 1 1 85%;
  max-width: 100%;
  max-height: 85%;
  box-sizing: border-box; }

[flex="90"] {
  flex: 1 1 90%;
  max-width: 90%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="90"],
[layout="row"] > [flex="90"] {
  flex: 1 1 90%;
  max-width: 90%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="90"],
[layout="column"] > [flex="90"] {
  flex: 1 1 90%;
  max-width: 100%;
  max-height: 90%;
  box-sizing: border-box; }

[flex="95"] {
  flex: 1 1 95%;
  max-width: 95%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="95"],
[layout="row"] > [flex="95"] {
  flex: 1 1 95%;
  max-width: 95%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="95"],
[layout="column"] > [flex="95"] {
  flex: 1 1 95%;
  max-width: 100%;
  max-height: 95%;
  box-sizing: border-box; }

[flex="100"] {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="100"],
[layout="row"] > [flex="100"] {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="100"],
[layout="column"] > [flex="100"] {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="33"], [layout="row"] > [flex="33"], [layout="row"] > [flex="33"], [layout="row"] > [flex="33"] {
  flex: 1 1 33%;
  max-width: calc(100% / 3);
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="34"], [layout="row"] > [flex="34"], [layout="row"] > [flex="34"], [layout="row"] > [flex="34"] {
  flex: 1 1 34%;
  max-width: 34%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="66"], [layout="row"] > [flex="66"], [layout="row"] > [flex="66"], [layout="row"] > [flex="66"] {
  flex: 1 1 66%;
  max-width: calc(200% / 3);
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="67"], [layout="row"] > [flex="67"], [layout="row"] > [flex="67"], [layout="row"] > [flex="67"] {
  flex: 1 1 67%;
  max-width: 67%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="33"], [layout="column"] > [flex="33"], [layout="column"] > [flex="33"], [layout="column"] > [flex="33"] {
  flex: 1 1 33%;
  max-width: 100%;
  max-height: calc(100% / 3);
  box-sizing: border-box; }

[layout="column"] > [flex="34"], [layout="column"] > [flex="34"], [layout="column"] > [flex="34"], [layout="column"] > [flex="34"] {
  flex: 1 1 34%;
  max-width: 100%;
  max-height: 34%;
  box-sizing: border-box; }

[layout="column"] > [flex="66"], [layout="column"] > [flex="66"], [layout="column"] > [flex="66"], [layout="column"] > [flex="66"] {
  flex: 1 1 66%;
  max-width: 100%;
  max-height: calc(200% / 3);
  box-sizing: border-box; }

[layout="column"] > [flex="67"], [layout="column"] > [flex="67"], [layout="column"] > [flex="67"], [layout="column"] > [flex="67"] {
  flex: 1 1 67%;
  max-width: 100%;
  max-height: 67%;
  box-sizing: border-box; }

[layout], [layout="column"], [layout="row"] {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex; }

[layout="column"] {
  flex-direction: column; }

[layout="row"] {
  flex-direction: row; }

[layout-padding] > [flex-sm], [layout-padding] > [flex-lt-md] {
  padding: 4px; }

[layout-padding],
[layout-padding] > [flex],
[layout-padding] > [flex-gt-sm],
[layout-padding] > [flex-md],
[layout-padding] > [flex-lt-lg] {
  padding: 8px; }

[layout-padding] > [flex-gt-md],
[layout-padding] > [flex-lg] {
  padding: 16px; }

[layout-margin] > [flex-sm],
[layout-margin] > [flex-lt-md] {
  margin: 4px; }

[layout-margin],
[layout-margin] > [flex],
[layout-margin] > [flex-gt-sm],
[layout-margin] > [flex-md],
[layout-margin] > [flex-lt-lg] {
  margin: 8px; }

[layout-margin] > [flex-gt-md],
[layout-margin] > [flex-lg] {
  margin: 16px; }

[layout-wrap] {
  flex-wrap: wrap; }

[layout-nowrap] {
  flex-wrap: nowrap; }

[layout-fill] {
  margin: 0;
  width: 100%;
  min-height: 100%;
  height: 100%; }

/**
 * `hide-gt-sm show-gt-lg` should hide from 600px to 1200px
 * `show-md hide-gt-sm` should show from 0px to 960px and hide at >960px
 * `hide-gt-md show-gt-sm` should show everywhere (show overrides hide)`
 *
 *  hide means hide everywhere
 *  Sizes:
 *         $layout-breakpoint-xs:     600px !default;
 *         $layout-breakpoint-sm:     960px !default;
 *         $layout-breakpoint-md:     1280px !default;
 *         $layout-breakpoint-lg:     1920px !default;
 */
@media (max-width: 599px) {
  [hide-xs]:not([show-xs]):not([show]), [hide]:not([show-xs]):not([show]) {
    display: none; }
  [flex-order-xs="-20"] {
    order: -20; }
  [flex-order-xs="-19"] {
    order: -19; }
  [flex-order-xs="-18"] {
    order: -18; }
  [flex-order-xs="-17"] {
    order: -17; }
  [flex-order-xs="-16"] {
    order: -16; }
  [flex-order-xs="-15"] {
    order: -15; }
  [flex-order-xs="-14"] {
    order: -14; }
  [flex-order-xs="-13"] {
    order: -13; }
  [flex-order-xs="-12"] {
    order: -12; }
  [flex-order-xs="-11"] {
    order: -11; }
  [flex-order-xs="-10"] {
    order: -10; }
  [flex-order-xs="-9"] {
    order: -9; }
  [flex-order-xs="-8"] {
    order: -8; }
  [flex-order-xs="-7"] {
    order: -7; }
  [flex-order-xs="-6"] {
    order: -6; }
  [flex-order-xs="-5"] {
    order: -5; }
  [flex-order-xs="-4"] {
    order: -4; }
  [flex-order-xs="-3"] {
    order: -3; }
  [flex-order-xs="-2"] {
    order: -2; }
  [flex-order-xs="-1"] {
    order: -1; }
  [flex-order-xs="0"] {
    order: 0; }
  [flex-order-xs="1"] {
    order: 1; }
  [flex-order-xs="2"] {
    order: 2; }
  [flex-order-xs="3"] {
    order: 3; }
  [flex-order-xs="4"] {
    order: 4; }
  [flex-order-xs="5"] {
    order: 5; }
  [flex-order-xs="6"] {
    order: 6; }
  [flex-order-xs="7"] {
    order: 7; }
  [flex-order-xs="8"] {
    order: 8; }
  [flex-order-xs="9"] {
    order: 9; }
  [flex-order-xs="10"] {
    order: 10; }
  [flex-order-xs="11"] {
    order: 11; }
  [flex-order-xs="12"] {
    order: 12; }
  [flex-order-xs="13"] {
    order: 13; }
  [flex-order-xs="14"] {
    order: 14; }
  [flex-order-xs="15"] {
    order: 15; }
  [flex-order-xs="16"] {
    order: 16; }
  [flex-order-xs="17"] {
    order: 17; }
  [flex-order-xs="18"] {
    order: 18; }
  [flex-order-xs="19"] {
    order: 19; }
  [flex-order-xs="20"] {
    order: 20; }
  [flex-offset-xs="0"] {
    margin-left: 0%; }
  [flex-offset-xs="5"] {
    margin-left: 5%; }
  [flex-offset-xs="10"] {
    margin-left: 10%; }
  [flex-offset-xs="15"] {
    margin-left: 15%; }
  [flex-offset-xs="20"] {
    margin-left: 20%; }
  [flex-offset-xs="25"] {
    margin-left: 25%; }
  [flex-offset-xs="30"] {
    margin-left: 30%; }
  [flex-offset-xs="35"] {
    margin-left: 35%; }
  [flex-offset-xs="40"] {
    margin-left: 40%; }
  [flex-offset-xs="45"] {
    margin-left: 45%; }
  [flex-offset-xs="50"] {
    margin-left: 50%; }
  [flex-offset-xs="55"] {
    margin-left: 55%; }
  [flex-offset-xs="60"] {
    margin-left: 60%; }
  [flex-offset-xs="65"] {
    margin-left: 65%; }
  [flex-offset-xs="70"] {
    margin-left: 70%; }
  [flex-offset-xs="75"] {
    margin-left: 75%; }
  [flex-offset-xs="80"] {
    margin-left: 80%; }
  [flex-offset-xs="85"] {
    margin-left: 85%; }
  [flex-offset-xs="90"] {
    margin-left: 90%; }
  [flex-offset-xs="95"] {
    margin-left: 95%; }
  [flex-offset-xs="33"] {
    margin-left: calc(100% / 3); }
  [flex-offset-xs="66"] {
    margin-left: calc(200% / 3); }
  [layout-align-xs],
  [layout-align-xs="start stretch"] {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  [layout-align-xs="start"],
  [layout-align-xs="start start"],
  [layout-align-xs="start center"],
  [layout-align-xs="start end"],
  [layout-align-xs="start stretch"] {
    justify-content: start; }
  [layout-align-xs="center"],
  [layout-align-xs="center start"],
  [layout-align-xs="center center"],
  [layout-align-xs="center end"],
  [layout-align-xs="center stretch"] {
    justify-content: center; }
  [layout-align-xs="end"],
  [layout-align-xs="end center"],
  [layout-align-xs="end start"],
  [layout-align-xs="end end"],
  [layout-align-xs="end stretch"] {
    justify-content: flex-end; }
  [layout-align-xs="space-around"],
  [layout-align-xs="space-around center"],
  [layout-align-xs="space-around start"],
  [layout-align-xs="space-around end"],
  [layout-align-xs="space-around stretch"] {
    justify-content: space-around; }
  [layout-align-xs="space-between"],
  [layout-align-xs="space-between center"],
  [layout-align-xs="space-between start"],
  [layout-align-xs="space-between end"],
  [layout-align-xs="space-between stretch"] {
    justify-content: space-between; }
  [layout-align-xs="start start"],
  [layout-align-xs="center start"],
  [layout-align-xs="end start"],
  [layout-align-xs="space-between start"],
  [layout-align-xs="space-around start"] {
    align-items: flex-start;
    align-content: flex-start; }
  [layout-align-xs="start center"],
  [layout-align-xs="center center"],
  [layout-align-xs="end center"],
  [layout-align-xs="space-between center"],
  [layout-align-xs="space-around center"] {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  [layout-align-xs="start center"] > *,
  [layout-align-xs="center center"] > *,
  [layout-align-xs="end center"] > *,
  [layout-align-xs="space-between center"] > *,
  [layout-align-xs="space-around center"] > * {
    max-width: 100%;
    box-sizing: border-box; }
  [layout-align-xs="start end"],
  [layout-align-xs="center end"],
  [layout-align-xs="end end"],
  [layout-align-xs="space-between end"],
  [layout-align-xs="space-around end"] {
    align-items: flex-end;
    align-content: flex-end; }
  [layout-align-xs="start stretch"],
  [layout-align-xs="center stretch"],
  [layout-align-xs="end stretch"],
  [layout-align-xs="space-between stretch"],
  [layout-align-xs="space-around stretch"] {
    align-items: stretch;
    align-content: stretch; }
  [flex-xs] {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (max-width: 599px) {
  [flex-xs] {
    flex: 1 1 0%; } }

@media (max-width: 599px) {
  [flex-xs-grow] {
    flex: 1 1 100%;
    box-sizing: border-box; }
  [flex-xs-initial] {
    flex: 0 1 auto;
    box-sizing: border-box; }
  [flex-xs-auto] {
    flex: 1 1 auto;
    box-sizing: border-box; }
  [flex-xs-none] {
    flex: 0 0 auto;
    box-sizing: border-box; }
  [flex-xs-noshrink] {
    flex: 1 0 auto;
    box-sizing: border-box; }
  [flex-xs-nogrow] {
    flex: 0 1 auto;
    box-sizing: border-box; }
  [flex-xs="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="0"],
  [layout-xs="row"] > [flex-xs="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="0"],
  [layout-xs="column"] > [flex-xs="0"] {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  [flex-xs="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="5"],
  [layout-xs="row"] > [flex-xs="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="5"],
  [layout-xs="column"] > [flex-xs="5"] {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  [flex-xs="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="10"],
  [layout-xs="row"] > [flex-xs="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="10"],
  [layout-xs="column"] > [flex-xs="10"] {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  [flex-xs="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="15"],
  [layout-xs="row"] > [flex-xs="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="15"],
  [layout-xs="column"] > [flex-xs="15"] {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  [flex-xs="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="20"],
  [layout-xs="row"] > [flex-xs="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="20"],
  [layout-xs="column"] > [flex-xs="20"] {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  [flex-xs="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="25"],
  [layout-xs="row"] > [flex-xs="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="25"],
  [layout-xs="column"] > [flex-xs="25"] {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  [flex-xs="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="30"],
  [layout-xs="row"] > [flex-xs="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="30"],
  [layout-xs="column"] > [flex-xs="30"] {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  [flex-xs="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="35"],
  [layout-xs="row"] > [flex-xs="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="35"],
  [layout-xs="column"] > [flex-xs="35"] {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  [flex-xs="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="40"],
  [layout-xs="row"] > [flex-xs="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="40"],
  [layout-xs="column"] > [flex-xs="40"] {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  [flex-xs="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="45"],
  [layout-xs="row"] > [flex-xs="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="45"],
  [layout-xs="column"] > [flex-xs="45"] {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  [flex-xs="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="50"],
  [layout-xs="row"] > [flex-xs="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="50"],
  [layout-xs="column"] > [flex-xs="50"] {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  [flex-xs="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="55"],
  [layout-xs="row"] > [flex-xs="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="55"],
  [layout-xs="column"] > [flex-xs="55"] {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  [flex-xs="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="60"],
  [layout-xs="row"] > [flex-xs="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="60"],
  [layout-xs="column"] > [flex-xs="60"] {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  [flex-xs="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="65"],
  [layout-xs="row"] > [flex-xs="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="65"],
  [layout-xs="column"] > [flex-xs="65"] {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  [flex-xs="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="70"],
  [layout-xs="row"] > [flex-xs="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="70"],
  [layout-xs="column"] > [flex-xs="70"] {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  [flex-xs="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="75"],
  [layout-xs="row"] > [flex-xs="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="75"],
  [layout-xs="column"] > [flex-xs="75"] {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  [flex-xs="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="80"],
  [layout-xs="row"] > [flex-xs="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="80"],
  [layout-xs="column"] > [flex-xs="80"] {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  [flex-xs="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="85"],
  [layout-xs="row"] > [flex-xs="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="85"],
  [layout-xs="column"] > [flex-xs="85"] {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  [flex-xs="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="90"],
  [layout-xs="row"] > [flex-xs="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="90"],
  [layout-xs="column"] > [flex-xs="90"] {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  [flex-xs="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="95"],
  [layout-xs="row"] > [flex-xs="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="95"],
  [layout-xs="column"] > [flex-xs="95"] {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  [flex-xs="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="100"],
  [layout-xs="row"] > [flex-xs="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="100"],
  [layout-xs="column"] > [flex-xs="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="33"], [layout="row"] > [flex-xs="33"], [layout-xs="row"] > [flex-xs="33"], [layout-xs="row"] > [flex-xs="33"] {
    flex: 1 1 33%;
    max-width: calc(100% / 3);
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="34"], [layout="row"] > [flex-xs="34"], [layout-xs="row"] > [flex-xs="34"], [layout-xs="row"] > [flex-xs="34"] {
    flex: 1 1 34%;
    max-width: 34%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="66"], [layout="row"] > [flex-xs="66"], [layout-xs="row"] > [flex-xs="66"], [layout-xs="row"] > [flex-xs="66"] {
    flex: 1 1 66%;
    max-width: calc(200% / 3);
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="67"], [layout="row"] > [flex-xs="67"], [layout-xs="row"] > [flex-xs="67"], [layout-xs="row"] > [flex-xs="67"] {
    flex: 1 1 67%;
    max-width: 67%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="33"], [layout="column"] > [flex-xs="33"], [layout-xs="column"] > [flex-xs="33"], [layout-xs="column"] > [flex-xs="33"] {
    flex: 1 1 33%;
    max-width: 100%;
    max-height: calc(100% / 3);
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="34"], [layout="column"] > [flex-xs="34"], [layout-xs="column"] > [flex-xs="34"], [layout-xs="column"] > [flex-xs="34"] {
    flex: 1 1 34%;
    max-width: 100%;
    max-height: 34%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="66"], [layout="column"] > [flex-xs="66"], [layout-xs="column"] > [flex-xs="66"], [layout-xs="column"] > [flex-xs="66"] {
    flex: 1 1 66%;
    max-width: 100%;
    max-height: calc(200% / 3);
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="67"], [layout="column"] > [flex-xs="67"], [layout-xs="column"] > [flex-xs="67"], [layout-xs="column"] > [flex-xs="67"] {
    flex: 1 1 67%;
    max-width: 100%;
    max-height: 67%;
    box-sizing: border-box; }
  [layout-xs], [layout-xs="column"], [layout-xs="row"] {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  [layout-xs="column"] {
    flex-direction: column; }
  [layout-xs="row"] {
    flex-direction: row; } }

@media (min-width: 600px) {
  [flex-order-gt-xs="-20"] {
    order: -20; }
  [flex-order-gt-xs="-19"] {
    order: -19; }
  [flex-order-gt-xs="-18"] {
    order: -18; }
  [flex-order-gt-xs="-17"] {
    order: -17; }
  [flex-order-gt-xs="-16"] {
    order: -16; }
  [flex-order-gt-xs="-15"] {
    order: -15; }
  [flex-order-gt-xs="-14"] {
    order: -14; }
  [flex-order-gt-xs="-13"] {
    order: -13; }
  [flex-order-gt-xs="-12"] {
    order: -12; }
  [flex-order-gt-xs="-11"] {
    order: -11; }
  [flex-order-gt-xs="-10"] {
    order: -10; }
  [flex-order-gt-xs="-9"] {
    order: -9; }
  [flex-order-gt-xs="-8"] {
    order: -8; }
  [flex-order-gt-xs="-7"] {
    order: -7; }
  [flex-order-gt-xs="-6"] {
    order: -6; }
  [flex-order-gt-xs="-5"] {
    order: -5; }
  [flex-order-gt-xs="-4"] {
    order: -4; }
  [flex-order-gt-xs="-3"] {
    order: -3; }
  [flex-order-gt-xs="-2"] {
    order: -2; }
  [flex-order-gt-xs="-1"] {
    order: -1; }
  [flex-order-gt-xs="0"] {
    order: 0; }
  [flex-order-gt-xs="1"] {
    order: 1; }
  [flex-order-gt-xs="2"] {
    order: 2; }
  [flex-order-gt-xs="3"] {
    order: 3; }
  [flex-order-gt-xs="4"] {
    order: 4; }
  [flex-order-gt-xs="5"] {
    order: 5; }
  [flex-order-gt-xs="6"] {
    order: 6; }
  [flex-order-gt-xs="7"] {
    order: 7; }
  [flex-order-gt-xs="8"] {
    order: 8; }
  [flex-order-gt-xs="9"] {
    order: 9; }
  [flex-order-gt-xs="10"] {
    order: 10; }
  [flex-order-gt-xs="11"] {
    order: 11; }
  [flex-order-gt-xs="12"] {
    order: 12; }
  [flex-order-gt-xs="13"] {
    order: 13; }
  [flex-order-gt-xs="14"] {
    order: 14; }
  [flex-order-gt-xs="15"] {
    order: 15; }
  [flex-order-gt-xs="16"] {
    order: 16; }
  [flex-order-gt-xs="17"] {
    order: 17; }
  [flex-order-gt-xs="18"] {
    order: 18; }
  [flex-order-gt-xs="19"] {
    order: 19; }
  [flex-order-gt-xs="20"] {
    order: 20; }
  [flex-offset-gt-xs="0"] {
    margin-left: 0%; }
  [flex-offset-gt-xs="5"] {
    margin-left: 5%; }
  [flex-offset-gt-xs="10"] {
    margin-left: 10%; }
  [flex-offset-gt-xs="15"] {
    margin-left: 15%; }
  [flex-offset-gt-xs="20"] {
    margin-left: 20%; }
  [flex-offset-gt-xs="25"] {
    margin-left: 25%; }
  [flex-offset-gt-xs="30"] {
    margin-left: 30%; }
  [flex-offset-gt-xs="35"] {
    margin-left: 35%; }
  [flex-offset-gt-xs="40"] {
    margin-left: 40%; }
  [flex-offset-gt-xs="45"] {
    margin-left: 45%; }
  [flex-offset-gt-xs="50"] {
    margin-left: 50%; }
  [flex-offset-gt-xs="55"] {
    margin-left: 55%; }
  [flex-offset-gt-xs="60"] {
    margin-left: 60%; }
  [flex-offset-gt-xs="65"] {
    margin-left: 65%; }
  [flex-offset-gt-xs="70"] {
    margin-left: 70%; }
  [flex-offset-gt-xs="75"] {
    margin-left: 75%; }
  [flex-offset-gt-xs="80"] {
    margin-left: 80%; }
  [flex-offset-gt-xs="85"] {
    margin-left: 85%; }
  [flex-offset-gt-xs="90"] {
    margin-left: 90%; }
  [flex-offset-gt-xs="95"] {
    margin-left: 95%; }
  [flex-offset-gt-xs="33"] {
    margin-left: calc(100% / 3); }
  [flex-offset-gt-xs="66"] {
    margin-left: calc(200% / 3); }
  [layout-align-gt-xs],
  [layout-align-gt-xs="start stretch"] {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  [layout-align-gt-xs="start"],
  [layout-align-gt-xs="start start"],
  [layout-align-gt-xs="start center"],
  [layout-align-gt-xs="start end"],
  [layout-align-gt-xs="start stretch"] {
    justify-content: start; }
  [layout-align-gt-xs="center"],
  [layout-align-gt-xs="center start"],
  [layout-align-gt-xs="center center"],
  [layout-align-gt-xs="center end"],
  [layout-align-gt-xs="center stretch"] {
    justify-content: center; }
  [layout-align-gt-xs="end"],
  [layout-align-gt-xs="end center"],
  [layout-align-gt-xs="end start"],
  [layout-align-gt-xs="end end"],
  [layout-align-gt-xs="end stretch"] {
    justify-content: flex-end; }
  [layout-align-gt-xs="space-around"],
  [layout-align-gt-xs="space-around center"],
  [layout-align-gt-xs="space-around start"],
  [layout-align-gt-xs="space-around end"],
  [layout-align-gt-xs="space-around stretch"] {
    justify-content: space-around; }
  [layout-align-gt-xs="space-between"],
  [layout-align-gt-xs="space-between center"],
  [layout-align-gt-xs="space-between start"],
  [layout-align-gt-xs="space-between end"],
  [layout-align-gt-xs="space-between stretch"] {
    justify-content: space-between; }
  [layout-align-gt-xs="start start"],
  [layout-align-gt-xs="center start"],
  [layout-align-gt-xs="end start"],
  [layout-align-gt-xs="space-between start"],
  [layout-align-gt-xs="space-around start"] {
    align-items: flex-start;
    align-content: flex-start; }
  [layout-align-gt-xs="start center"],
  [layout-align-gt-xs="center center"],
  [layout-align-gt-xs="end center"],
  [layout-align-gt-xs="space-between center"],
  [layout-align-gt-xs="space-around center"] {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  [layout-align-gt-xs="start center"] > *,
  [layout-align-gt-xs="center center"] > *,
  [layout-align-gt-xs="end center"] > *,
  [layout-align-gt-xs="space-between center"] > *,
  [layout-align-gt-xs="space-around center"] > * {
    max-width: 100%;
    box-sizing: border-box; }
  [layout-align-gt-xs="start end"],
  [layout-align-gt-xs="center end"],
  [layout-align-gt-xs="end end"],
  [layout-align-gt-xs="space-between end"],
  [layout-align-gt-xs="space-around end"] {
    align-items: flex-end;
    align-content: flex-end; }
  [layout-align-gt-xs="start stretch"],
  [layout-align-gt-xs="center stretch"],
  [layout-align-gt-xs="end stretch"],
  [layout-align-gt-xs="space-between stretch"],
  [layout-align-gt-xs="space-around stretch"] {
    align-items: stretch;
    align-content: stretch; }
  [flex-gt-xs] {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (min-width: 600px) {
  [flex-gt-xs] {
    flex: 1 1 0%; } }

@media (min-width: 600px) {
  [flex-gt-xs-grow] {
    flex: 1 1 100%;
    box-sizing: border-box; }
  [flex-gt-xs-initial] {
    flex: 0 1 auto;
    box-sizing: border-box; }
  [flex-gt-xs-auto] {
    flex: 1 1 auto;
    box-sizing: border-box; }
  [flex-gt-xs-none] {
    flex: 0 0 auto;
    box-sizing: border-box; }
  [flex-gt-xs-noshrink] {
    flex: 1 0 auto;
    box-sizing: border-box; }
  [flex-gt-xs-nogrow] {
    flex: 0 1 auto;
    box-sizing: border-box; }
  [flex-gt-xs="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="0"],
  [layout-gt-xs="row"] > [flex-gt-xs="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="0"],
  [layout-gt-xs="column"] > [flex-gt-xs="0"] {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  [flex-gt-xs="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="5"],
  [layout-gt-xs="row"] > [flex-gt-xs="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="5"],
  [layout-gt-xs="column"] > [flex-gt-xs="5"] {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  [flex-gt-xs="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="10"],
  [layout-gt-xs="row"] > [flex-gt-xs="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="10"],
  [layout-gt-xs="column"] > [flex-gt-xs="10"] {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  [flex-gt-xs="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="15"],
  [layout-gt-xs="row"] > [flex-gt-xs="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="15"],
  [layout-gt-xs="column"] > [flex-gt-xs="15"] {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  [flex-gt-xs="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="20"],
  [layout-gt-xs="row"] > [flex-gt-xs="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="20"],
  [layout-gt-xs="column"] > [flex-gt-xs="20"] {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  [flex-gt-xs="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="25"],
  [layout-gt-xs="row"] > [flex-gt-xs="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="25"],
  [layout-gt-xs="column"] > [flex-gt-xs="25"] {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  [flex-gt-xs="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="30"],
  [layout-gt-xs="row"] > [flex-gt-xs="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="30"],
  [layout-gt-xs="column"] > [flex-gt-xs="30"] {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  [flex-gt-xs="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="35"],
  [layout-gt-xs="row"] > [flex-gt-xs="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="35"],
  [layout-gt-xs="column"] > [flex-gt-xs="35"] {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  [flex-gt-xs="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="40"],
  [layout-gt-xs="row"] > [flex-gt-xs="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="40"],
  [layout-gt-xs="column"] > [flex-gt-xs="40"] {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  [flex-gt-xs="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="45"],
  [layout-gt-xs="row"] > [flex-gt-xs="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="45"],
  [layout-gt-xs="column"] > [flex-gt-xs="45"] {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  [flex-gt-xs="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="50"],
  [layout-gt-xs="row"] > [flex-gt-xs="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="50"],
  [layout-gt-xs="column"] > [flex-gt-xs="50"] {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  [flex-gt-xs="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="55"],
  [layout-gt-xs="row"] > [flex-gt-xs="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="55"],
  [layout-gt-xs="column"] > [flex-gt-xs="55"] {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  [flex-gt-xs="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="60"],
  [layout-gt-xs="row"] > [flex-gt-xs="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="60"],
  [layout-gt-xs="column"] > [flex-gt-xs="60"] {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  [flex-gt-xs="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="65"],
  [layout-gt-xs="row"] > [flex-gt-xs="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="65"],
  [layout-gt-xs="column"] > [flex-gt-xs="65"] {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  [flex-gt-xs="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="70"],
  [layout-gt-xs="row"] > [flex-gt-xs="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="70"],
  [layout-gt-xs="column"] > [flex-gt-xs="70"] {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  [flex-gt-xs="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="75"],
  [layout-gt-xs="row"] > [flex-gt-xs="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="75"],
  [layout-gt-xs="column"] > [flex-gt-xs="75"] {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  [flex-gt-xs="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="80"],
  [layout-gt-xs="row"] > [flex-gt-xs="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="80"],
  [layout-gt-xs="column"] > [flex-gt-xs="80"] {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  [flex-gt-xs="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="85"],
  [layout-gt-xs="row"] > [flex-gt-xs="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="85"],
  [layout-gt-xs="column"] > [flex-gt-xs="85"] {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  [flex-gt-xs="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="90"],
  [layout-gt-xs="row"] > [flex-gt-xs="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="90"],
  [layout-gt-xs="column"] > [flex-gt-xs="90"] {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  [flex-gt-xs="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="95"],
  [layout-gt-xs="row"] > [flex-gt-xs="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="95"],
  [layout-gt-xs="column"] > [flex-gt-xs="95"] {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  [flex-gt-xs="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="100"],
  [layout-gt-xs="row"] > [flex-gt-xs="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="100"],
  [layout-gt-xs="column"] > [flex-gt-xs="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="33"], [layout="row"] > [flex-gt-xs="33"], [layout-gt-xs="row"] > [flex-gt-xs="33"], [layout-gt-xs="row"] > [flex-gt-xs="33"] {
    flex: 1 1 33%;
    max-width: calc(100% / 3);
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="34"], [layout="row"] > [flex-gt-xs="34"], [layout-gt-xs="row"] > [flex-gt-xs="34"], [layout-gt-xs="row"] > [flex-gt-xs="34"] {
    flex: 1 1 34%;
    max-width: 34%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="66"], [layout="row"] > [flex-gt-xs="66"], [layout-gt-xs="row"] > [flex-gt-xs="66"], [layout-gt-xs="row"] > [flex-gt-xs="66"] {
    flex: 1 1 66%;
    max-width: calc(200% / 3);
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="67"], [layout="row"] > [flex-gt-xs="67"], [layout-gt-xs="row"] > [flex-gt-xs="67"], [layout-gt-xs="row"] > [flex-gt-xs="67"] {
    flex: 1 1 67%;
    max-width: 67%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="33"], [layout="column"] > [flex-gt-xs="33"], [layout-gt-xs="column"] > [flex-gt-xs="33"], [layout-gt-xs="column"] > [flex-gt-xs="33"] {
    flex: 1 1 33%;
    max-width: 100%;
    max-height: calc(100% / 3);
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="34"], [layout="column"] > [flex-gt-xs="34"], [layout-gt-xs="column"] > [flex-gt-xs="34"], [layout-gt-xs="column"] > [flex-gt-xs="34"] {
    flex: 1 1 34%;
    max-width: 100%;
    max-height: 34%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="66"], [layout="column"] > [flex-gt-xs="66"], [layout-gt-xs="column"] > [flex-gt-xs="66"], [layout-gt-xs="column"] > [flex-gt-xs="66"] {
    flex: 1 1 66%;
    max-width: 100%;
    max-height: calc(200% / 3);
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="67"], [layout="column"] > [flex-gt-xs="67"], [layout-gt-xs="column"] > [flex-gt-xs="67"], [layout-gt-xs="column"] > [flex-gt-xs="67"] {
    flex: 1 1 67%;
    max-width: 100%;
    max-height: 67%;
    box-sizing: border-box; }
  [layout-gt-xs], [layout-gt-xs="column"], [layout-gt-xs="row"] {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  [layout-gt-xs="column"] {
    flex-direction: column; }
  [layout-gt-xs="row"] {
    flex-direction: row; } }

@media (min-width: 600px) and (max-width: 599px) {
  [hide-sm]:not([show-gt-xs]):not([show-sm]):not([show]), [hide-gt-xs]:not([show-gt-xs]):not([show-sm]):not([show]) {
    display: none; }
  [hide-sm]:not([show-sm]):not([show]) {
    display: none; }
  [flex-order-sm="-20"] {
    order: -20; }
  [flex-order-sm="-19"] {
    order: -19; }
  [flex-order-sm="-18"] {
    order: -18; }
  [flex-order-sm="-17"] {
    order: -17; }
  [flex-order-sm="-16"] {
    order: -16; }
  [flex-order-sm="-15"] {
    order: -15; }
  [flex-order-sm="-14"] {
    order: -14; }
  [flex-order-sm="-13"] {
    order: -13; }
  [flex-order-sm="-12"] {
    order: -12; }
  [flex-order-sm="-11"] {
    order: -11; }
  [flex-order-sm="-10"] {
    order: -10; }
  [flex-order-sm="-9"] {
    order: -9; }
  [flex-order-sm="-8"] {
    order: -8; }
  [flex-order-sm="-7"] {
    order: -7; }
  [flex-order-sm="-6"] {
    order: -6; }
  [flex-order-sm="-5"] {
    order: -5; }
  [flex-order-sm="-4"] {
    order: -4; }
  [flex-order-sm="-3"] {
    order: -3; }
  [flex-order-sm="-2"] {
    order: -2; }
  [flex-order-sm="-1"] {
    order: -1; }
  [flex-order-sm="0"] {
    order: 0; }
  [flex-order-sm="1"] {
    order: 1; }
  [flex-order-sm="2"] {
    order: 2; }
  [flex-order-sm="3"] {
    order: 3; }
  [flex-order-sm="4"] {
    order: 4; }
  [flex-order-sm="5"] {
    order: 5; }
  [flex-order-sm="6"] {
    order: 6; }
  [flex-order-sm="7"] {
    order: 7; }
  [flex-order-sm="8"] {
    order: 8; }
  [flex-order-sm="9"] {
    order: 9; }
  [flex-order-sm="10"] {
    order: 10; }
  [flex-order-sm="11"] {
    order: 11; }
  [flex-order-sm="12"] {
    order: 12; }
  [flex-order-sm="13"] {
    order: 13; }
  [flex-order-sm="14"] {
    order: 14; }
  [flex-order-sm="15"] {
    order: 15; }
  [flex-order-sm="16"] {
    order: 16; }
  [flex-order-sm="17"] {
    order: 17; }
  [flex-order-sm="18"] {
    order: 18; }
  [flex-order-sm="19"] {
    order: 19; }
  [flex-order-sm="20"] {
    order: 20; }
  [flex-offset-sm="0"] {
    margin-left: 0%; }
  [flex-offset-sm="5"] {
    margin-left: 5%; }
  [flex-offset-sm="10"] {
    margin-left: 10%; }
  [flex-offset-sm="15"] {
    margin-left: 15%; }
  [flex-offset-sm="20"] {
    margin-left: 20%; }
  [flex-offset-sm="25"] {
    margin-left: 25%; }
  [flex-offset-sm="30"] {
    margin-left: 30%; }
  [flex-offset-sm="35"] {
    margin-left: 35%; }
  [flex-offset-sm="40"] {
    margin-left: 40%; }
  [flex-offset-sm="45"] {
    margin-left: 45%; }
  [flex-offset-sm="50"] {
    margin-left: 50%; }
  [flex-offset-sm="55"] {
    margin-left: 55%; }
  [flex-offset-sm="60"] {
    margin-left: 60%; }
  [flex-offset-sm="65"] {
    margin-left: 65%; }
  [flex-offset-sm="70"] {
    margin-left: 70%; }
  [flex-offset-sm="75"] {
    margin-left: 75%; }
  [flex-offset-sm="80"] {
    margin-left: 80%; }
  [flex-offset-sm="85"] {
    margin-left: 85%; }
  [flex-offset-sm="90"] {
    margin-left: 90%; }
  [flex-offset-sm="95"] {
    margin-left: 95%; }
  [flex-offset-sm="33"] {
    margin-left: calc(100% / 3); }
  [flex-offset-sm="66"] {
    margin-left: calc(200% / 3); }
  [layout-align-sm],
  [layout-align-sm="start stretch"] {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  [layout-align-sm="start"],
  [layout-align-sm="start start"],
  [layout-align-sm="start center"],
  [layout-align-sm="start end"],
  [layout-align-sm="start stretch"] {
    justify-content: start; }
  [layout-align-sm="center"],
  [layout-align-sm="center start"],
  [layout-align-sm="center center"],
  [layout-align-sm="center end"],
  [layout-align-sm="center stretch"] {
    justify-content: center; }
  [layout-align-sm="end"],
  [layout-align-sm="end center"],
  [layout-align-sm="end start"],
  [layout-align-sm="end end"],
  [layout-align-sm="end stretch"] {
    justify-content: flex-end; }
  [layout-align-sm="space-around"],
  [layout-align-sm="space-around center"],
  [layout-align-sm="space-around start"],
  [layout-align-sm="space-around end"],
  [layout-align-sm="space-around stretch"] {
    justify-content: space-around; }
  [layout-align-sm="space-between"],
  [layout-align-sm="space-between center"],
  [layout-align-sm="space-between start"],
  [layout-align-sm="space-between end"],
  [layout-align-sm="space-between stretch"] {
    justify-content: space-between; }
  [layout-align-sm="start start"],
  [layout-align-sm="center start"],
  [layout-align-sm="end start"],
  [layout-align-sm="space-between start"],
  [layout-align-sm="space-around start"] {
    align-items: flex-start;
    align-content: flex-start; }
  [layout-align-sm="start center"],
  [layout-align-sm="center center"],
  [layout-align-sm="end center"],
  [layout-align-sm="space-between center"],
  [layout-align-sm="space-around center"] {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  [layout-align-sm="start center"] > *,
  [layout-align-sm="center center"] > *,
  [layout-align-sm="end center"] > *,
  [layout-align-sm="space-between center"] > *,
  [layout-align-sm="space-around center"] > * {
    max-width: 100%;
    box-sizing: border-box; }
  [layout-align-sm="start end"],
  [layout-align-sm="center end"],
  [layout-align-sm="end end"],
  [layout-align-sm="space-between end"],
  [layout-align-sm="space-around end"] {
    align-items: flex-end;
    align-content: flex-end; }
  [layout-align-sm="start stretch"],
  [layout-align-sm="center stretch"],
  [layout-align-sm="end stretch"],
  [layout-align-sm="space-between stretch"],
  [layout-align-sm="space-around stretch"] {
    align-items: stretch;
    align-content: stretch; }
  [flex-sm] {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (min-width: 600px) and (max-width: 599px) {
  [flex-sm] {
    flex: 1 1 0%; } }

@media (min-width: 600px) and (max-width: 599px) {
  [flex-sm-grow] {
    flex: 1 1 100%;
    box-sizing: border-box; }
  [flex-sm-initial] {
    flex: 0 1 auto;
    box-sizing: border-box; }
  [flex-sm-auto] {
    flex: 1 1 auto;
    box-sizing: border-box; }
  [flex-sm-none] {
    flex: 0 0 auto;
    box-sizing: border-box; }
  [flex-sm-noshrink] {
    flex: 1 0 auto;
    box-sizing: border-box; }
  [flex-sm-nogrow] {
    flex: 0 1 auto;
    box-sizing: border-box; }
  [flex-sm="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="0"],
  [layout-sm="row"] > [flex-sm="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="0"],
  [layout-sm="column"] > [flex-sm="0"] {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  [flex-sm="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="5"],
  [layout-sm="row"] > [flex-sm="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="5"],
  [layout-sm="column"] > [flex-sm="5"] {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  [flex-sm="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="10"],
  [layout-sm="row"] > [flex-sm="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="10"],
  [layout-sm="column"] > [flex-sm="10"] {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  [flex-sm="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="15"],
  [layout-sm="row"] > [flex-sm="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="15"],
  [layout-sm="column"] > [flex-sm="15"] {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  [flex-sm="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="20"],
  [layout-sm="row"] > [flex-sm="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="20"],
  [layout-sm="column"] > [flex-sm="20"] {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  [flex-sm="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="25"],
  [layout-sm="row"] > [flex-sm="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="25"],
  [layout-sm="column"] > [flex-sm="25"] {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  [flex-sm="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="30"],
  [layout-sm="row"] > [flex-sm="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="30"],
  [layout-sm="column"] > [flex-sm="30"] {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  [flex-sm="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="35"],
  [layout-sm="row"] > [flex-sm="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="35"],
  [layout-sm="column"] > [flex-sm="35"] {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  [flex-sm="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="40"],
  [layout-sm="row"] > [flex-sm="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="40"],
  [layout-sm="column"] > [flex-sm="40"] {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  [flex-sm="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="45"],
  [layout-sm="row"] > [flex-sm="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="45"],
  [layout-sm="column"] > [flex-sm="45"] {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  [flex-sm="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="50"],
  [layout-sm="row"] > [flex-sm="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="50"],
  [layout-sm="column"] > [flex-sm="50"] {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  [flex-sm="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="55"],
  [layout-sm="row"] > [flex-sm="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="55"],
  [layout-sm="column"] > [flex-sm="55"] {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  [flex-sm="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="60"],
  [layout-sm="row"] > [flex-sm="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="60"],
  [layout-sm="column"] > [flex-sm="60"] {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  [flex-sm="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="65"],
  [layout-sm="row"] > [flex-sm="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="65"],
  [layout-sm="column"] > [flex-sm="65"] {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  [flex-sm="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="70"],
  [layout-sm="row"] > [flex-sm="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="70"],
  [layout-sm="column"] > [flex-sm="70"] {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  [flex-sm="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="75"],
  [layout-sm="row"] > [flex-sm="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="75"],
  [layout-sm="column"] > [flex-sm="75"] {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  [flex-sm="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="80"],
  [layout-sm="row"] > [flex-sm="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="80"],
  [layout-sm="column"] > [flex-sm="80"] {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  [flex-sm="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="85"],
  [layout-sm="row"] > [flex-sm="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="85"],
  [layout-sm="column"] > [flex-sm="85"] {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  [flex-sm="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="90"],
  [layout-sm="row"] > [flex-sm="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="90"],
  [layout-sm="column"] > [flex-sm="90"] {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  [flex-sm="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="95"],
  [layout-sm="row"] > [flex-sm="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="95"],
  [layout-sm="column"] > [flex-sm="95"] {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  [flex-sm="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="100"],
  [layout-sm="row"] > [flex-sm="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="100"],
  [layout-sm="column"] > [flex-sm="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="33"], [layout="row"] > [flex-sm="33"], [layout-sm="row"] > [flex-sm="33"], [layout-sm="row"] > [flex-sm="33"] {
    flex: 1 1 33%;
    max-width: calc(100% / 3);
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="34"], [layout="row"] > [flex-sm="34"], [layout-sm="row"] > [flex-sm="34"], [layout-sm="row"] > [flex-sm="34"] {
    flex: 1 1 34%;
    max-width: 34%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="66"], [layout="row"] > [flex-sm="66"], [layout-sm="row"] > [flex-sm="66"], [layout-sm="row"] > [flex-sm="66"] {
    flex: 1 1 66%;
    max-width: calc(200% / 3);
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="67"], [layout="row"] > [flex-sm="67"], [layout-sm="row"] > [flex-sm="67"], [layout-sm="row"] > [flex-sm="67"] {
    flex: 1 1 67%;
    max-width: 67%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="33"], [layout="column"] > [flex-sm="33"], [layout-sm="column"] > [flex-sm="33"], [layout-sm="column"] > [flex-sm="33"] {
    flex: 1 1 33%;
    max-width: 100%;
    max-height: calc(100% / 3);
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="34"], [layout="column"] > [flex-sm="34"], [layout-sm="column"] > [flex-sm="34"], [layout-sm="column"] > [flex-sm="34"] {
    flex: 1 1 34%;
    max-width: 100%;
    max-height: 34%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="66"], [layout="column"] > [flex-sm="66"], [layout-sm="column"] > [flex-sm="66"], [layout-sm="column"] > [flex-sm="66"] {
    flex: 1 1 66%;
    max-width: 100%;
    max-height: calc(200% / 3);
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="67"], [layout="column"] > [flex-sm="67"], [layout-sm="column"] > [flex-sm="67"], [layout-sm="column"] > [flex-sm="67"] {
    flex: 1 1 67%;
    max-width: 100%;
    max-height: 67%;
    box-sizing: border-box; }
  [layout-sm], [layout-sm="column"], [layout-sm="row"] {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  [layout-sm="column"] {
    flex-direction: column; }
  [layout-sm="row"] {
    flex-direction: row; } }

@media (min-width: 600px) {
  [flex-order-gt-sm="-20"] {
    order: -20; }
  [flex-order-gt-sm="-19"] {
    order: -19; }
  [flex-order-gt-sm="-18"] {
    order: -18; }
  [flex-order-gt-sm="-17"] {
    order: -17; }
  [flex-order-gt-sm="-16"] {
    order: -16; }
  [flex-order-gt-sm="-15"] {
    order: -15; }
  [flex-order-gt-sm="-14"] {
    order: -14; }
  [flex-order-gt-sm="-13"] {
    order: -13; }
  [flex-order-gt-sm="-12"] {
    order: -12; }
  [flex-order-gt-sm="-11"] {
    order: -11; }
  [flex-order-gt-sm="-10"] {
    order: -10; }
  [flex-order-gt-sm="-9"] {
    order: -9; }
  [flex-order-gt-sm="-8"] {
    order: -8; }
  [flex-order-gt-sm="-7"] {
    order: -7; }
  [flex-order-gt-sm="-6"] {
    order: -6; }
  [flex-order-gt-sm="-5"] {
    order: -5; }
  [flex-order-gt-sm="-4"] {
    order: -4; }
  [flex-order-gt-sm="-3"] {
    order: -3; }
  [flex-order-gt-sm="-2"] {
    order: -2; }
  [flex-order-gt-sm="-1"] {
    order: -1; }
  [flex-order-gt-sm="0"] {
    order: 0; }
  [flex-order-gt-sm="1"] {
    order: 1; }
  [flex-order-gt-sm="2"] {
    order: 2; }
  [flex-order-gt-sm="3"] {
    order: 3; }
  [flex-order-gt-sm="4"] {
    order: 4; }
  [flex-order-gt-sm="5"] {
    order: 5; }
  [flex-order-gt-sm="6"] {
    order: 6; }
  [flex-order-gt-sm="7"] {
    order: 7; }
  [flex-order-gt-sm="8"] {
    order: 8; }
  [flex-order-gt-sm="9"] {
    order: 9; }
  [flex-order-gt-sm="10"] {
    order: 10; }
  [flex-order-gt-sm="11"] {
    order: 11; }
  [flex-order-gt-sm="12"] {
    order: 12; }
  [flex-order-gt-sm="13"] {
    order: 13; }
  [flex-order-gt-sm="14"] {
    order: 14; }
  [flex-order-gt-sm="15"] {
    order: 15; }
  [flex-order-gt-sm="16"] {
    order: 16; }
  [flex-order-gt-sm="17"] {
    order: 17; }
  [flex-order-gt-sm="18"] {
    order: 18; }
  [flex-order-gt-sm="19"] {
    order: 19; }
  [flex-order-gt-sm="20"] {
    order: 20; }
  [flex-offset-gt-sm="0"] {
    margin-left: 0%; }
  [flex-offset-gt-sm="5"] {
    margin-left: 5%; }
  [flex-offset-gt-sm="10"] {
    margin-left: 10%; }
  [flex-offset-gt-sm="15"] {
    margin-left: 15%; }
  [flex-offset-gt-sm="20"] {
    margin-left: 20%; }
  [flex-offset-gt-sm="25"] {
    margin-left: 25%; }
  [flex-offset-gt-sm="30"] {
    margin-left: 30%; }
  [flex-offset-gt-sm="35"] {
    margin-left: 35%; }
  [flex-offset-gt-sm="40"] {
    margin-left: 40%; }
  [flex-offset-gt-sm="45"] {
    margin-left: 45%; }
  [flex-offset-gt-sm="50"] {
    margin-left: 50%; }
  [flex-offset-gt-sm="55"] {
    margin-left: 55%; }
  [flex-offset-gt-sm="60"] {
    margin-left: 60%; }
  [flex-offset-gt-sm="65"] {
    margin-left: 65%; }
  [flex-offset-gt-sm="70"] {
    margin-left: 70%; }
  [flex-offset-gt-sm="75"] {
    margin-left: 75%; }
  [flex-offset-gt-sm="80"] {
    margin-left: 80%; }
  [flex-offset-gt-sm="85"] {
    margin-left: 85%; }
  [flex-offset-gt-sm="90"] {
    margin-left: 90%; }
  [flex-offset-gt-sm="95"] {
    margin-left: 95%; }
  [flex-offset-gt-sm="33"] {
    margin-left: calc(100% / 3); }
  [flex-offset-gt-sm="66"] {
    margin-left: calc(200% / 3); }
  [layout-align-gt-sm],
  [layout-align-gt-sm="start stretch"] {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  [layout-align-gt-sm="start"],
  [layout-align-gt-sm="start start"],
  [layout-align-gt-sm="start center"],
  [layout-align-gt-sm="start end"],
  [layout-align-gt-sm="start stretch"] {
    justify-content: start; }
  [layout-align-gt-sm="center"],
  [layout-align-gt-sm="center start"],
  [layout-align-gt-sm="center center"],
  [layout-align-gt-sm="center end"],
  [layout-align-gt-sm="center stretch"] {
    justify-content: center; }
  [layout-align-gt-sm="end"],
  [layout-align-gt-sm="end center"],
  [layout-align-gt-sm="end start"],
  [layout-align-gt-sm="end end"],
  [layout-align-gt-sm="end stretch"] {
    justify-content: flex-end; }
  [layout-align-gt-sm="space-around"],
  [layout-align-gt-sm="space-around center"],
  [layout-align-gt-sm="space-around start"],
  [layout-align-gt-sm="space-around end"],
  [layout-align-gt-sm="space-around stretch"] {
    justify-content: space-around; }
  [layout-align-gt-sm="space-between"],
  [layout-align-gt-sm="space-between center"],
  [layout-align-gt-sm="space-between start"],
  [layout-align-gt-sm="space-between end"],
  [layout-align-gt-sm="space-between stretch"] {
    justify-content: space-between; }
  [layout-align-gt-sm="start start"],
  [layout-align-gt-sm="center start"],
  [layout-align-gt-sm="end start"],
  [layout-align-gt-sm="space-between start"],
  [layout-align-gt-sm="space-around start"] {
    align-items: flex-start;
    align-content: flex-start; }
  [layout-align-gt-sm="start center"],
  [layout-align-gt-sm="center center"],
  [layout-align-gt-sm="end center"],
  [layout-align-gt-sm="space-between center"],
  [layout-align-gt-sm="space-around center"] {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  [layout-align-gt-sm="start center"] > *,
  [layout-align-gt-sm="center center"] > *,
  [layout-align-gt-sm="end center"] > *,
  [layout-align-gt-sm="space-between center"] > *,
  [layout-align-gt-sm="space-around center"] > * {
    max-width: 100%;
    box-sizing: border-box; }
  [layout-align-gt-sm="start end"],
  [layout-align-gt-sm="center end"],
  [layout-align-gt-sm="end end"],
  [layout-align-gt-sm="space-between end"],
  [layout-align-gt-sm="space-around end"] {
    align-items: flex-end;
    align-content: flex-end; }
  [layout-align-gt-sm="start stretch"],
  [layout-align-gt-sm="center stretch"],
  [layout-align-gt-sm="end stretch"],
  [layout-align-gt-sm="space-between stretch"],
  [layout-align-gt-sm="space-around stretch"] {
    align-items: stretch;
    align-content: stretch; }
  [flex-gt-sm] {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (min-width: 600px) {
  [flex-gt-sm] {
    flex: 1 1 0%; } }

@media (min-width: 600px) {
  [flex-gt-sm-grow] {
    flex: 1 1 100%;
    box-sizing: border-box; }
  [flex-gt-sm-initial] {
    flex: 0 1 auto;
    box-sizing: border-box; }
  [flex-gt-sm-auto] {
    flex: 1 1 auto;
    box-sizing: border-box; }
  [flex-gt-sm-none] {
    flex: 0 0 auto;
    box-sizing: border-box; }
  [flex-gt-sm-noshrink] {
    flex: 1 0 auto;
    box-sizing: border-box; }
  [flex-gt-sm-nogrow] {
    flex: 0 1 auto;
    box-sizing: border-box; }
  [flex-gt-sm="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="0"],
  [layout-gt-sm="row"] > [flex-gt-sm="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="0"],
  [layout-gt-sm="column"] > [flex-gt-sm="0"] {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  [flex-gt-sm="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="5"],
  [layout-gt-sm="row"] > [flex-gt-sm="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="5"],
  [layout-gt-sm="column"] > [flex-gt-sm="5"] {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  [flex-gt-sm="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="10"],
  [layout-gt-sm="row"] > [flex-gt-sm="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="10"],
  [layout-gt-sm="column"] > [flex-gt-sm="10"] {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  [flex-gt-sm="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="15"],
  [layout-gt-sm="row"] > [flex-gt-sm="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="15"],
  [layout-gt-sm="column"] > [flex-gt-sm="15"] {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  [flex-gt-sm="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="20"],
  [layout-gt-sm="row"] > [flex-gt-sm="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="20"],
  [layout-gt-sm="column"] > [flex-gt-sm="20"] {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  [flex-gt-sm="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="25"],
  [layout-gt-sm="row"] > [flex-gt-sm="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="25"],
  [layout-gt-sm="column"] > [flex-gt-sm="25"] {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  [flex-gt-sm="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="30"],
  [layout-gt-sm="row"] > [flex-gt-sm="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="30"],
  [layout-gt-sm="column"] > [flex-gt-sm="30"] {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  [flex-gt-sm="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="35"],
  [layout-gt-sm="row"] > [flex-gt-sm="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="35"],
  [layout-gt-sm="column"] > [flex-gt-sm="35"] {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  [flex-gt-sm="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="40"],
  [layout-gt-sm="row"] > [flex-gt-sm="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="40"],
  [layout-gt-sm="column"] > [flex-gt-sm="40"] {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  [flex-gt-sm="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="45"],
  [layout-gt-sm="row"] > [flex-gt-sm="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="45"],
  [layout-gt-sm="column"] > [flex-gt-sm="45"] {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  [flex-gt-sm="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="50"],
  [layout-gt-sm="row"] > [flex-gt-sm="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="50"],
  [layout-gt-sm="column"] > [flex-gt-sm="50"] {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  [flex-gt-sm="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="55"],
  [layout-gt-sm="row"] > [flex-gt-sm="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="55"],
  [layout-gt-sm="column"] > [flex-gt-sm="55"] {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  [flex-gt-sm="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="60"],
  [layout-gt-sm="row"] > [flex-gt-sm="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="60"],
  [layout-gt-sm="column"] > [flex-gt-sm="60"] {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  [flex-gt-sm="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="65"],
  [layout-gt-sm="row"] > [flex-gt-sm="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="65"],
  [layout-gt-sm="column"] > [flex-gt-sm="65"] {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  [flex-gt-sm="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="70"],
  [layout-gt-sm="row"] > [flex-gt-sm="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="70"],
  [layout-gt-sm="column"] > [flex-gt-sm="70"] {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  [flex-gt-sm="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="75"],
  [layout-gt-sm="row"] > [flex-gt-sm="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="75"],
  [layout-gt-sm="column"] > [flex-gt-sm="75"] {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  [flex-gt-sm="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="80"],
  [layout-gt-sm="row"] > [flex-gt-sm="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="80"],
  [layout-gt-sm="column"] > [flex-gt-sm="80"] {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  [flex-gt-sm="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="85"],
  [layout-gt-sm="row"] > [flex-gt-sm="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="85"],
  [layout-gt-sm="column"] > [flex-gt-sm="85"] {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  [flex-gt-sm="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="90"],
  [layout-gt-sm="row"] > [flex-gt-sm="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="90"],
  [layout-gt-sm="column"] > [flex-gt-sm="90"] {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  [flex-gt-sm="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="95"],
  [layout-gt-sm="row"] > [flex-gt-sm="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="95"],
  [layout-gt-sm="column"] > [flex-gt-sm="95"] {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  [flex-gt-sm="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="100"],
  [layout-gt-sm="row"] > [flex-gt-sm="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="100"],
  [layout-gt-sm="column"] > [flex-gt-sm="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="33"], [layout="row"] > [flex-gt-sm="33"], [layout-gt-sm="row"] > [flex-gt-sm="33"], [layout-gt-sm="row"] > [flex-gt-sm="33"] {
    flex: 1 1 33%;
    max-width: calc(100% / 3);
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="34"], [layout="row"] > [flex-gt-sm="34"], [layout-gt-sm="row"] > [flex-gt-sm="34"], [layout-gt-sm="row"] > [flex-gt-sm="34"] {
    flex: 1 1 34%;
    max-width: 34%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="66"], [layout="row"] > [flex-gt-sm="66"], [layout-gt-sm="row"] > [flex-gt-sm="66"], [layout-gt-sm="row"] > [flex-gt-sm="66"] {
    flex: 1 1 66%;
    max-width: calc(200% / 3);
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="67"], [layout="row"] > [flex-gt-sm="67"], [layout-gt-sm="row"] > [flex-gt-sm="67"], [layout-gt-sm="row"] > [flex-gt-sm="67"] {
    flex: 1 1 67%;
    max-width: 67%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="33"], [layout="column"] > [flex-gt-sm="33"], [layout-gt-sm="column"] > [flex-gt-sm="33"], [layout-gt-sm="column"] > [flex-gt-sm="33"] {
    flex: 1 1 33%;
    max-width: 100%;
    max-height: calc(100% / 3);
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="34"], [layout="column"] > [flex-gt-sm="34"], [layout-gt-sm="column"] > [flex-gt-sm="34"], [layout-gt-sm="column"] > [flex-gt-sm="34"] {
    flex: 1 1 34%;
    max-width: 100%;
    max-height: 34%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="66"], [layout="column"] > [flex-gt-sm="66"], [layout-gt-sm="column"] > [flex-gt-sm="66"], [layout-gt-sm="column"] > [flex-gt-sm="66"] {
    flex: 1 1 66%;
    max-width: 100%;
    max-height: calc(200% / 3);
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="67"], [layout="column"] > [flex-gt-sm="67"], [layout-gt-sm="column"] > [flex-gt-sm="67"], [layout-gt-sm="column"] > [flex-gt-sm="67"] {
    flex: 1 1 67%;
    max-width: 100%;
    max-height: 67%;
    box-sizing: border-box; }
  [layout-gt-sm], [layout-gt-sm="column"], [layout-gt-sm="row"] {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  [layout-gt-sm="column"] {
    flex-direction: column; }
  [layout-gt-sm="row"] {
    flex-direction: row; } }

@media (min-width: 600px) and (max-width: 959px) {
  [hide]:not([show-gt-xs]):not([show-gt-sm]):not([show-md]):not([show]), [hide-gt-xs]:not([show-gt-xs]):not([show-gt-sm]):not([show-md]):not([show]), [hide-gt-sm]:not([show-gt-xs]):not([show-gt-sm]):not([show-md]):not([show]) {
    display: none; }
  [hide-md]:not([show-md]):not([show]) {
    display: none; }
  [flex-order-md="-20"] {
    order: -20; }
  [flex-order-md="-19"] {
    order: -19; }
  [flex-order-md="-18"] {
    order: -18; }
  [flex-order-md="-17"] {
    order: -17; }
  [flex-order-md="-16"] {
    order: -16; }
  [flex-order-md="-15"] {
    order: -15; }
  [flex-order-md="-14"] {
    order: -14; }
  [flex-order-md="-13"] {
    order: -13; }
  [flex-order-md="-12"] {
    order: -12; }
  [flex-order-md="-11"] {
    order: -11; }
  [flex-order-md="-10"] {
    order: -10; }
  [flex-order-md="-9"] {
    order: -9; }
  [flex-order-md="-8"] {
    order: -8; }
  [flex-order-md="-7"] {
    order: -7; }
  [flex-order-md="-6"] {
    order: -6; }
  [flex-order-md="-5"] {
    order: -5; }
  [flex-order-md="-4"] {
    order: -4; }
  [flex-order-md="-3"] {
    order: -3; }
  [flex-order-md="-2"] {
    order: -2; }
  [flex-order-md="-1"] {
    order: -1; }
  [flex-order-md="0"] {
    order: 0; }
  [flex-order-md="1"] {
    order: 1; }
  [flex-order-md="2"] {
    order: 2; }
  [flex-order-md="3"] {
    order: 3; }
  [flex-order-md="4"] {
    order: 4; }
  [flex-order-md="5"] {
    order: 5; }
  [flex-order-md="6"] {
    order: 6; }
  [flex-order-md="7"] {
    order: 7; }
  [flex-order-md="8"] {
    order: 8; }
  [flex-order-md="9"] {
    order: 9; }
  [flex-order-md="10"] {
    order: 10; }
  [flex-order-md="11"] {
    order: 11; }
  [flex-order-md="12"] {
    order: 12; }
  [flex-order-md="13"] {
    order: 13; }
  [flex-order-md="14"] {
    order: 14; }
  [flex-order-md="15"] {
    order: 15; }
  [flex-order-md="16"] {
    order: 16; }
  [flex-order-md="17"] {
    order: 17; }
  [flex-order-md="18"] {
    order: 18; }
  [flex-order-md="19"] {
    order: 19; }
  [flex-order-md="20"] {
    order: 20; }
  [flex-offset-md="0"] {
    margin-left: 0%; }
  [flex-offset-md="5"] {
    margin-left: 5%; }
  [flex-offset-md="10"] {
    margin-left: 10%; }
  [flex-offset-md="15"] {
    margin-left: 15%; }
  [flex-offset-md="20"] {
    margin-left: 20%; }
  [flex-offset-md="25"] {
    margin-left: 25%; }
  [flex-offset-md="30"] {
    margin-left: 30%; }
  [flex-offset-md="35"] {
    margin-left: 35%; }
  [flex-offset-md="40"] {
    margin-left: 40%; }
  [flex-offset-md="45"] {
    margin-left: 45%; }
  [flex-offset-md="50"] {
    margin-left: 50%; }
  [flex-offset-md="55"] {
    margin-left: 55%; }
  [flex-offset-md="60"] {
    margin-left: 60%; }
  [flex-offset-md="65"] {
    margin-left: 65%; }
  [flex-offset-md="70"] {
    margin-left: 70%; }
  [flex-offset-md="75"] {
    margin-left: 75%; }
  [flex-offset-md="80"] {
    margin-left: 80%; }
  [flex-offset-md="85"] {
    margin-left: 85%; }
  [flex-offset-md="90"] {
    margin-left: 90%; }
  [flex-offset-md="95"] {
    margin-left: 95%; }
  [flex-offset-md="33"] {
    margin-left: calc(100% / 3); }
  [flex-offset-md="66"] {
    margin-left: calc(200% / 3); }
  [layout-align-md],
  [layout-align-md="start stretch"] {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  [layout-align-md="start"],
  [layout-align-md="start start"],
  [layout-align-md="start center"],
  [layout-align-md="start end"],
  [layout-align-md="start stretch"] {
    justify-content: start; }
  [layout-align-md="center"],
  [layout-align-md="center start"],
  [layout-align-md="center center"],
  [layout-align-md="center end"],
  [layout-align-md="center stretch"] {
    justify-content: center; }
  [layout-align-md="end"],
  [layout-align-md="end center"],
  [layout-align-md="end start"],
  [layout-align-md="end end"],
  [layout-align-md="end stretch"] {
    justify-content: flex-end; }
  [layout-align-md="space-around"],
  [layout-align-md="space-around center"],
  [layout-align-md="space-around start"],
  [layout-align-md="space-around end"],
  [layout-align-md="space-around stretch"] {
    justify-content: space-around; }
  [layout-align-md="space-between"],
  [layout-align-md="space-between center"],
  [layout-align-md="space-between start"],
  [layout-align-md="space-between end"],
  [layout-align-md="space-between stretch"] {
    justify-content: space-between; }
  [layout-align-md="start start"],
  [layout-align-md="center start"],
  [layout-align-md="end start"],
  [layout-align-md="space-between start"],
  [layout-align-md="space-around start"] {
    align-items: flex-start;
    align-content: flex-start; }
  [layout-align-md="start center"],
  [layout-align-md="center center"],
  [layout-align-md="end center"],
  [layout-align-md="space-between center"],
  [layout-align-md="space-around center"] {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  [layout-align-md="start center"] > *,
  [layout-align-md="center center"] > *,
  [layout-align-md="end center"] > *,
  [layout-align-md="space-between center"] > *,
  [layout-align-md="space-around center"] > * {
    max-width: 100%;
    box-sizing: border-box; }
  [layout-align-md="start end"],
  [layout-align-md="center end"],
  [layout-align-md="end end"],
  [layout-align-md="space-between end"],
  [layout-align-md="space-around end"] {
    align-items: flex-end;
    align-content: flex-end; }
  [layout-align-md="start stretch"],
  [layout-align-md="center stretch"],
  [layout-align-md="end stretch"],
  [layout-align-md="space-between stretch"],
  [layout-align-md="space-around stretch"] {
    align-items: stretch;
    align-content: stretch; }
  [flex-md] {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (min-width: 600px) and (max-width: 959px) {
  [flex-md] {
    flex: 1 1 0%; } }

@media (min-width: 600px) and (max-width: 959px) {
  [flex-md-grow] {
    flex: 1 1 100%;
    box-sizing: border-box; }
  [flex-md-initial] {
    flex: 0 1 auto;
    box-sizing: border-box; }
  [flex-md-auto] {
    flex: 1 1 auto;
    box-sizing: border-box; }
  [flex-md-none] {
    flex: 0 0 auto;
    box-sizing: border-box; }
  [flex-md-noshrink] {
    flex: 1 0 auto;
    box-sizing: border-box; }
  [flex-md-nogrow] {
    flex: 0 1 auto;
    box-sizing: border-box; }
  [flex-md="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="0"],
  [layout-md="row"] > [flex-md="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="0"],
  [layout-md="column"] > [flex-md="0"] {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  [flex-md="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="5"],
  [layout-md="row"] > [flex-md="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="5"],
  [layout-md="column"] > [flex-md="5"] {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  [flex-md="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="10"],
  [layout-md="row"] > [flex-md="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="10"],
  [layout-md="column"] > [flex-md="10"] {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  [flex-md="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="15"],
  [layout-md="row"] > [flex-md="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="15"],
  [layout-md="column"] > [flex-md="15"] {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  [flex-md="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="20"],
  [layout-md="row"] > [flex-md="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="20"],
  [layout-md="column"] > [flex-md="20"] {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  [flex-md="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="25"],
  [layout-md="row"] > [flex-md="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="25"],
  [layout-md="column"] > [flex-md="25"] {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  [flex-md="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="30"],
  [layout-md="row"] > [flex-md="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="30"],
  [layout-md="column"] > [flex-md="30"] {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  [flex-md="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="35"],
  [layout-md="row"] > [flex-md="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="35"],
  [layout-md="column"] > [flex-md="35"] {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  [flex-md="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="40"],
  [layout-md="row"] > [flex-md="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="40"],
  [layout-md="column"] > [flex-md="40"] {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  [flex-md="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="45"],
  [layout-md="row"] > [flex-md="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="45"],
  [layout-md="column"] > [flex-md="45"] {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  [flex-md="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="50"],
  [layout-md="row"] > [flex-md="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="50"],
  [layout-md="column"] > [flex-md="50"] {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  [flex-md="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="55"],
  [layout-md="row"] > [flex-md="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="55"],
  [layout-md="column"] > [flex-md="55"] {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  [flex-md="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="60"],
  [layout-md="row"] > [flex-md="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="60"],
  [layout-md="column"] > [flex-md="60"] {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  [flex-md="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="65"],
  [layout-md="row"] > [flex-md="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="65"],
  [layout-md="column"] > [flex-md="65"] {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  [flex-md="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="70"],
  [layout-md="row"] > [flex-md="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="70"],
  [layout-md="column"] > [flex-md="70"] {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  [flex-md="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="75"],
  [layout-md="row"] > [flex-md="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="75"],
  [layout-md="column"] > [flex-md="75"] {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  [flex-md="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="80"],
  [layout-md="row"] > [flex-md="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="80"],
  [layout-md="column"] > [flex-md="80"] {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  [flex-md="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="85"],
  [layout-md="row"] > [flex-md="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="85"],
  [layout-md="column"] > [flex-md="85"] {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  [flex-md="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="90"],
  [layout-md="row"] > [flex-md="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="90"],
  [layout-md="column"] > [flex-md="90"] {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  [flex-md="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="95"],
  [layout-md="row"] > [flex-md="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="95"],
  [layout-md="column"] > [flex-md="95"] {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  [flex-md="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="100"],
  [layout-md="row"] > [flex-md="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="100"],
  [layout-md="column"] > [flex-md="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="33"], [layout="row"] > [flex-md="33"], [layout-md="row"] > [flex-md="33"], [layout-md="row"] > [flex-md="33"] {
    flex: 1 1 33%;
    max-width: calc(100% / 3);
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="34"], [layout="row"] > [flex-md="34"], [layout-md="row"] > [flex-md="34"], [layout-md="row"] > [flex-md="34"] {
    flex: 1 1 34%;
    max-width: 34%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="66"], [layout="row"] > [flex-md="66"], [layout-md="row"] > [flex-md="66"], [layout-md="row"] > [flex-md="66"] {
    flex: 1 1 66%;
    max-width: calc(200% / 3);
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="67"], [layout="row"] > [flex-md="67"], [layout-md="row"] > [flex-md="67"], [layout-md="row"] > [flex-md="67"] {
    flex: 1 1 67%;
    max-width: 67%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="33"], [layout="column"] > [flex-md="33"], [layout-md="column"] > [flex-md="33"], [layout-md="column"] > [flex-md="33"] {
    flex: 1 1 33%;
    max-width: 100%;
    max-height: calc(100% / 3);
    box-sizing: border-box; }
  [layout="column"] > [flex-md="34"], [layout="column"] > [flex-md="34"], [layout-md="column"] > [flex-md="34"], [layout-md="column"] > [flex-md="34"] {
    flex: 1 1 34%;
    max-width: 100%;
    max-height: 34%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="66"], [layout="column"] > [flex-md="66"], [layout-md="column"] > [flex-md="66"], [layout-md="column"] > [flex-md="66"] {
    flex: 1 1 66%;
    max-width: 100%;
    max-height: calc(200% / 3);
    box-sizing: border-box; }
  [layout="column"] > [flex-md="67"], [layout="column"] > [flex-md="67"], [layout-md="column"] > [flex-md="67"], [layout-md="column"] > [flex-md="67"] {
    flex: 1 1 67%;
    max-width: 100%;
    max-height: 67%;
    box-sizing: border-box; }
  [layout-md], [layout-md="column"], [layout-md="row"] {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  [layout-md="column"] {
    flex-direction: column; }
  [layout-md="row"] {
    flex-direction: row; } }

@media (min-width: 960px) {
  [flex-order-gt-md="-20"] {
    order: -20; }
  [flex-order-gt-md="-19"] {
    order: -19; }
  [flex-order-gt-md="-18"] {
    order: -18; }
  [flex-order-gt-md="-17"] {
    order: -17; }
  [flex-order-gt-md="-16"] {
    order: -16; }
  [flex-order-gt-md="-15"] {
    order: -15; }
  [flex-order-gt-md="-14"] {
    order: -14; }
  [flex-order-gt-md="-13"] {
    order: -13; }
  [flex-order-gt-md="-12"] {
    order: -12; }
  [flex-order-gt-md="-11"] {
    order: -11; }
  [flex-order-gt-md="-10"] {
    order: -10; }
  [flex-order-gt-md="-9"] {
    order: -9; }
  [flex-order-gt-md="-8"] {
    order: -8; }
  [flex-order-gt-md="-7"] {
    order: -7; }
  [flex-order-gt-md="-6"] {
    order: -6; }
  [flex-order-gt-md="-5"] {
    order: -5; }
  [flex-order-gt-md="-4"] {
    order: -4; }
  [flex-order-gt-md="-3"] {
    order: -3; }
  [flex-order-gt-md="-2"] {
    order: -2; }
  [flex-order-gt-md="-1"] {
    order: -1; }
  [flex-order-gt-md="0"] {
    order: 0; }
  [flex-order-gt-md="1"] {
    order: 1; }
  [flex-order-gt-md="2"] {
    order: 2; }
  [flex-order-gt-md="3"] {
    order: 3; }
  [flex-order-gt-md="4"] {
    order: 4; }
  [flex-order-gt-md="5"] {
    order: 5; }
  [flex-order-gt-md="6"] {
    order: 6; }
  [flex-order-gt-md="7"] {
    order: 7; }
  [flex-order-gt-md="8"] {
    order: 8; }
  [flex-order-gt-md="9"] {
    order: 9; }
  [flex-order-gt-md="10"] {
    order: 10; }
  [flex-order-gt-md="11"] {
    order: 11; }
  [flex-order-gt-md="12"] {
    order: 12; }
  [flex-order-gt-md="13"] {
    order: 13; }
  [flex-order-gt-md="14"] {
    order: 14; }
  [flex-order-gt-md="15"] {
    order: 15; }
  [flex-order-gt-md="16"] {
    order: 16; }
  [flex-order-gt-md="17"] {
    order: 17; }
  [flex-order-gt-md="18"] {
    order: 18; }
  [flex-order-gt-md="19"] {
    order: 19; }
  [flex-order-gt-md="20"] {
    order: 20; }
  [flex-offset-gt-md="0"] {
    margin-left: 0%; }
  [flex-offset-gt-md="5"] {
    margin-left: 5%; }
  [flex-offset-gt-md="10"] {
    margin-left: 10%; }
  [flex-offset-gt-md="15"] {
    margin-left: 15%; }
  [flex-offset-gt-md="20"] {
    margin-left: 20%; }
  [flex-offset-gt-md="25"] {
    margin-left: 25%; }
  [flex-offset-gt-md="30"] {
    margin-left: 30%; }
  [flex-offset-gt-md="35"] {
    margin-left: 35%; }
  [flex-offset-gt-md="40"] {
    margin-left: 40%; }
  [flex-offset-gt-md="45"] {
    margin-left: 45%; }
  [flex-offset-gt-md="50"] {
    margin-left: 50%; }
  [flex-offset-gt-md="55"] {
    margin-left: 55%; }
  [flex-offset-gt-md="60"] {
    margin-left: 60%; }
  [flex-offset-gt-md="65"] {
    margin-left: 65%; }
  [flex-offset-gt-md="70"] {
    margin-left: 70%; }
  [flex-offset-gt-md="75"] {
    margin-left: 75%; }
  [flex-offset-gt-md="80"] {
    margin-left: 80%; }
  [flex-offset-gt-md="85"] {
    margin-left: 85%; }
  [flex-offset-gt-md="90"] {
    margin-left: 90%; }
  [flex-offset-gt-md="95"] {
    margin-left: 95%; }
  [flex-offset-gt-md="33"] {
    margin-left: calc(100% / 3); }
  [flex-offset-gt-md="66"] {
    margin-left: calc(200% / 3); }
  [layout-align-gt-md],
  [layout-align-gt-md="start stretch"] {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  [layout-align-gt-md="start"],
  [layout-align-gt-md="start start"],
  [layout-align-gt-md="start center"],
  [layout-align-gt-md="start end"],
  [layout-align-gt-md="start stretch"] {
    justify-content: start; }
  [layout-align-gt-md="center"],
  [layout-align-gt-md="center start"],
  [layout-align-gt-md="center center"],
  [layout-align-gt-md="center end"],
  [layout-align-gt-md="center stretch"] {
    justify-content: center; }
  [layout-align-gt-md="end"],
  [layout-align-gt-md="end center"],
  [layout-align-gt-md="end start"],
  [layout-align-gt-md="end end"],
  [layout-align-gt-md="end stretch"] {
    justify-content: flex-end; }
  [layout-align-gt-md="space-around"],
  [layout-align-gt-md="space-around center"],
  [layout-align-gt-md="space-around start"],
  [layout-align-gt-md="space-around end"],
  [layout-align-gt-md="space-around stretch"] {
    justify-content: space-around; }
  [layout-align-gt-md="space-between"],
  [layout-align-gt-md="space-between center"],
  [layout-align-gt-md="space-between start"],
  [layout-align-gt-md="space-between end"],
  [layout-align-gt-md="space-between stretch"] {
    justify-content: space-between; }
  [layout-align-gt-md="start start"],
  [layout-align-gt-md="center start"],
  [layout-align-gt-md="end start"],
  [layout-align-gt-md="space-between start"],
  [layout-align-gt-md="space-around start"] {
    align-items: flex-start;
    align-content: flex-start; }
  [layout-align-gt-md="start center"],
  [layout-align-gt-md="center center"],
  [layout-align-gt-md="end center"],
  [layout-align-gt-md="space-between center"],
  [layout-align-gt-md="space-around center"] {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  [layout-align-gt-md="start center"] > *,
  [layout-align-gt-md="center center"] > *,
  [layout-align-gt-md="end center"] > *,
  [layout-align-gt-md="space-between center"] > *,
  [layout-align-gt-md="space-around center"] > * {
    max-width: 100%;
    box-sizing: border-box; }
  [layout-align-gt-md="start end"],
  [layout-align-gt-md="center end"],
  [layout-align-gt-md="end end"],
  [layout-align-gt-md="space-between end"],
  [layout-align-gt-md="space-around end"] {
    align-items: flex-end;
    align-content: flex-end; }
  [layout-align-gt-md="start stretch"],
  [layout-align-gt-md="center stretch"],
  [layout-align-gt-md="end stretch"],
  [layout-align-gt-md="space-between stretch"],
  [layout-align-gt-md="space-around stretch"] {
    align-items: stretch;
    align-content: stretch; }
  [flex-gt-md] {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (min-width: 960px) {
  [flex-gt-md] {
    flex: 1 1 0%; } }

@media (min-width: 960px) {
  [flex-gt-md-grow] {
    flex: 1 1 100%;
    box-sizing: border-box; }
  [flex-gt-md-initial] {
    flex: 0 1 auto;
    box-sizing: border-box; }
  [flex-gt-md-auto] {
    flex: 1 1 auto;
    box-sizing: border-box; }
  [flex-gt-md-none] {
    flex: 0 0 auto;
    box-sizing: border-box; }
  [flex-gt-md-noshrink] {
    flex: 1 0 auto;
    box-sizing: border-box; }
  [flex-gt-md-nogrow] {
    flex: 0 1 auto;
    box-sizing: border-box; }
  [flex-gt-md="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="0"],
  [layout-gt-md="row"] > [flex-gt-md="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="0"],
  [layout-gt-md="column"] > [flex-gt-md="0"] {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  [flex-gt-md="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="5"],
  [layout-gt-md="row"] > [flex-gt-md="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="5"],
  [layout-gt-md="column"] > [flex-gt-md="5"] {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  [flex-gt-md="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="10"],
  [layout-gt-md="row"] > [flex-gt-md="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="10"],
  [layout-gt-md="column"] > [flex-gt-md="10"] {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  [flex-gt-md="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="15"],
  [layout-gt-md="row"] > [flex-gt-md="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="15"],
  [layout-gt-md="column"] > [flex-gt-md="15"] {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  [flex-gt-md="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="20"],
  [layout-gt-md="row"] > [flex-gt-md="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="20"],
  [layout-gt-md="column"] > [flex-gt-md="20"] {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  [flex-gt-md="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="25"],
  [layout-gt-md="row"] > [flex-gt-md="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="25"],
  [layout-gt-md="column"] > [flex-gt-md="25"] {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  [flex-gt-md="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="30"],
  [layout-gt-md="row"] > [flex-gt-md="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="30"],
  [layout-gt-md="column"] > [flex-gt-md="30"] {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  [flex-gt-md="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="35"],
  [layout-gt-md="row"] > [flex-gt-md="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="35"],
  [layout-gt-md="column"] > [flex-gt-md="35"] {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  [flex-gt-md="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="40"],
  [layout-gt-md="row"] > [flex-gt-md="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="40"],
  [layout-gt-md="column"] > [flex-gt-md="40"] {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  [flex-gt-md="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="45"],
  [layout-gt-md="row"] > [flex-gt-md="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="45"],
  [layout-gt-md="column"] > [flex-gt-md="45"] {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  [flex-gt-md="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="50"],
  [layout-gt-md="row"] > [flex-gt-md="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="50"],
  [layout-gt-md="column"] > [flex-gt-md="50"] {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  [flex-gt-md="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="55"],
  [layout-gt-md="row"] > [flex-gt-md="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="55"],
  [layout-gt-md="column"] > [flex-gt-md="55"] {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  [flex-gt-md="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="60"],
  [layout-gt-md="row"] > [flex-gt-md="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="60"],
  [layout-gt-md="column"] > [flex-gt-md="60"] {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  [flex-gt-md="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="65"],
  [layout-gt-md="row"] > [flex-gt-md="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="65"],
  [layout-gt-md="column"] > [flex-gt-md="65"] {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  [flex-gt-md="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="70"],
  [layout-gt-md="row"] > [flex-gt-md="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="70"],
  [layout-gt-md="column"] > [flex-gt-md="70"] {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  [flex-gt-md="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="75"],
  [layout-gt-md="row"] > [flex-gt-md="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="75"],
  [layout-gt-md="column"] > [flex-gt-md="75"] {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  [flex-gt-md="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="80"],
  [layout-gt-md="row"] > [flex-gt-md="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="80"],
  [layout-gt-md="column"] > [flex-gt-md="80"] {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  [flex-gt-md="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="85"],
  [layout-gt-md="row"] > [flex-gt-md="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="85"],
  [layout-gt-md="column"] > [flex-gt-md="85"] {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  [flex-gt-md="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="90"],
  [layout-gt-md="row"] > [flex-gt-md="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="90"],
  [layout-gt-md="column"] > [flex-gt-md="90"] {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  [flex-gt-md="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="95"],
  [layout-gt-md="row"] > [flex-gt-md="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="95"],
  [layout-gt-md="column"] > [flex-gt-md="95"] {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  [flex-gt-md="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="100"],
  [layout-gt-md="row"] > [flex-gt-md="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="100"],
  [layout-gt-md="column"] > [flex-gt-md="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="33"], [layout="row"] > [flex-gt-md="33"], [layout-gt-md="row"] > [flex-gt-md="33"], [layout-gt-md="row"] > [flex-gt-md="33"] {
    flex: 1 1 33%;
    max-width: calc(100% / 3);
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="34"], [layout="row"] > [flex-gt-md="34"], [layout-gt-md="row"] > [flex-gt-md="34"], [layout-gt-md="row"] > [flex-gt-md="34"] {
    flex: 1 1 34%;
    max-width: 34%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="66"], [layout="row"] > [flex-gt-md="66"], [layout-gt-md="row"] > [flex-gt-md="66"], [layout-gt-md="row"] > [flex-gt-md="66"] {
    flex: 1 1 66%;
    max-width: calc(200% / 3);
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="67"], [layout="row"] > [flex-gt-md="67"], [layout-gt-md="row"] > [flex-gt-md="67"], [layout-gt-md="row"] > [flex-gt-md="67"] {
    flex: 1 1 67%;
    max-width: 67%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="33"], [layout="column"] > [flex-gt-md="33"], [layout-gt-md="column"] > [flex-gt-md="33"], [layout-gt-md="column"] > [flex-gt-md="33"] {
    flex: 1 1 33%;
    max-width: 100%;
    max-height: calc(100% / 3);
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="34"], [layout="column"] > [flex-gt-md="34"], [layout-gt-md="column"] > [flex-gt-md="34"], [layout-gt-md="column"] > [flex-gt-md="34"] {
    flex: 1 1 34%;
    max-width: 100%;
    max-height: 34%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="66"], [layout="column"] > [flex-gt-md="66"], [layout-gt-md="column"] > [flex-gt-md="66"], [layout-gt-md="column"] > [flex-gt-md="66"] {
    flex: 1 1 66%;
    max-width: 100%;
    max-height: calc(200% / 3);
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="67"], [layout="column"] > [flex-gt-md="67"], [layout-gt-md="column"] > [flex-gt-md="67"], [layout-gt-md="column"] > [flex-gt-md="67"] {
    flex: 1 1 67%;
    max-width: 100%;
    max-height: 67%;
    box-sizing: border-box; }
  [layout-gt-md], [layout-gt-md="column"], [layout-gt-md="row"] {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  [layout-gt-md="column"] {
    flex-direction: column; }
  [layout-gt-md="row"] {
    flex-direction: row; } }

@media (min-width: 960px) and (max-width: 1279px) {
  [hide]:not([show-gt-xs]):not([show-gt-sm]):not([show-gt-md]):not([show-lg]):not([show]), [hide-gt-xs]:not([show-gt-xs]):not([show-gt-sm]):not([show-gt-md]):not([show-lg]):not([show]), [hide-gt-sm]:not([show-gt-xs]):not([show-gt-sm]):not([show-gt-md]):not([show-lg]):not([show]), [hide-gt-md]:not([show-gt-xs]):not([show-gt-sm]):not([show-gt-md]):not([show-lg]):not([show]) {
    display: none; }
  [hide-lg]:not([show-lg]):not([show]) {
    display: none; }
  [flex-order-lg="-20"] {
    order: -20; }
  [flex-order-lg="-19"] {
    order: -19; }
  [flex-order-lg="-18"] {
    order: -18; }
  [flex-order-lg="-17"] {
    order: -17; }
  [flex-order-lg="-16"] {
    order: -16; }
  [flex-order-lg="-15"] {
    order: -15; }
  [flex-order-lg="-14"] {
    order: -14; }
  [flex-order-lg="-13"] {
    order: -13; }
  [flex-order-lg="-12"] {
    order: -12; }
  [flex-order-lg="-11"] {
    order: -11; }
  [flex-order-lg="-10"] {
    order: -10; }
  [flex-order-lg="-9"] {
    order: -9; }
  [flex-order-lg="-8"] {
    order: -8; }
  [flex-order-lg="-7"] {
    order: -7; }
  [flex-order-lg="-6"] {
    order: -6; }
  [flex-order-lg="-5"] {
    order: -5; }
  [flex-order-lg="-4"] {
    order: -4; }
  [flex-order-lg="-3"] {
    order: -3; }
  [flex-order-lg="-2"] {
    order: -2; }
  [flex-order-lg="-1"] {
    order: -1; }
  [flex-order-lg="0"] {
    order: 0; }
  [flex-order-lg="1"] {
    order: 1; }
  [flex-order-lg="2"] {
    order: 2; }
  [flex-order-lg="3"] {
    order: 3; }
  [flex-order-lg="4"] {
    order: 4; }
  [flex-order-lg="5"] {
    order: 5; }
  [flex-order-lg="6"] {
    order: 6; }
  [flex-order-lg="7"] {
    order: 7; }
  [flex-order-lg="8"] {
    order: 8; }
  [flex-order-lg="9"] {
    order: 9; }
  [flex-order-lg="10"] {
    order: 10; }
  [flex-order-lg="11"] {
    order: 11; }
  [flex-order-lg="12"] {
    order: 12; }
  [flex-order-lg="13"] {
    order: 13; }
  [flex-order-lg="14"] {
    order: 14; }
  [flex-order-lg="15"] {
    order: 15; }
  [flex-order-lg="16"] {
    order: 16; }
  [flex-order-lg="17"] {
    order: 17; }
  [flex-order-lg="18"] {
    order: 18; }
  [flex-order-lg="19"] {
    order: 19; }
  [flex-order-lg="20"] {
    order: 20; }
  [flex-offset-lg="0"] {
    margin-left: 0%; }
  [flex-offset-lg="5"] {
    margin-left: 5%; }
  [flex-offset-lg="10"] {
    margin-left: 10%; }
  [flex-offset-lg="15"] {
    margin-left: 15%; }
  [flex-offset-lg="20"] {
    margin-left: 20%; }
  [flex-offset-lg="25"] {
    margin-left: 25%; }
  [flex-offset-lg="30"] {
    margin-left: 30%; }
  [flex-offset-lg="35"] {
    margin-left: 35%; }
  [flex-offset-lg="40"] {
    margin-left: 40%; }
  [flex-offset-lg="45"] {
    margin-left: 45%; }
  [flex-offset-lg="50"] {
    margin-left: 50%; }
  [flex-offset-lg="55"] {
    margin-left: 55%; }
  [flex-offset-lg="60"] {
    margin-left: 60%; }
  [flex-offset-lg="65"] {
    margin-left: 65%; }
  [flex-offset-lg="70"] {
    margin-left: 70%; }
  [flex-offset-lg="75"] {
    margin-left: 75%; }
  [flex-offset-lg="80"] {
    margin-left: 80%; }
  [flex-offset-lg="85"] {
    margin-left: 85%; }
  [flex-offset-lg="90"] {
    margin-left: 90%; }
  [flex-offset-lg="95"] {
    margin-left: 95%; }
  [flex-offset-lg="33"] {
    margin-left: calc(100% / 3); }
  [flex-offset-lg="66"] {
    margin-left: calc(200% / 3); }
  [layout-align-lg],
  [layout-align-lg="start stretch"] {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  [layout-align-lg="start"],
  [layout-align-lg="start start"],
  [layout-align-lg="start center"],
  [layout-align-lg="start end"],
  [layout-align-lg="start stretch"] {
    justify-content: start; }
  [layout-align-lg="center"],
  [layout-align-lg="center start"],
  [layout-align-lg="center center"],
  [layout-align-lg="center end"],
  [layout-align-lg="center stretch"] {
    justify-content: center; }
  [layout-align-lg="end"],
  [layout-align-lg="end center"],
  [layout-align-lg="end start"],
  [layout-align-lg="end end"],
  [layout-align-lg="end stretch"] {
    justify-content: flex-end; }
  [layout-align-lg="space-around"],
  [layout-align-lg="space-around center"],
  [layout-align-lg="space-around start"],
  [layout-align-lg="space-around end"],
  [layout-align-lg="space-around stretch"] {
    justify-content: space-around; }
  [layout-align-lg="space-between"],
  [layout-align-lg="space-between center"],
  [layout-align-lg="space-between start"],
  [layout-align-lg="space-between end"],
  [layout-align-lg="space-between stretch"] {
    justify-content: space-between; }
  [layout-align-lg="start start"],
  [layout-align-lg="center start"],
  [layout-align-lg="end start"],
  [layout-align-lg="space-between start"],
  [layout-align-lg="space-around start"] {
    align-items: flex-start;
    align-content: flex-start; }
  [layout-align-lg="start center"],
  [layout-align-lg="center center"],
  [layout-align-lg="end center"],
  [layout-align-lg="space-between center"],
  [layout-align-lg="space-around center"] {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  [layout-align-lg="start center"] > *,
  [layout-align-lg="center center"] > *,
  [layout-align-lg="end center"] > *,
  [layout-align-lg="space-between center"] > *,
  [layout-align-lg="space-around center"] > * {
    max-width: 100%;
    box-sizing: border-box; }
  [layout-align-lg="start end"],
  [layout-align-lg="center end"],
  [layout-align-lg="end end"],
  [layout-align-lg="space-between end"],
  [layout-align-lg="space-around end"] {
    align-items: flex-end;
    align-content: flex-end; }
  [layout-align-lg="start stretch"],
  [layout-align-lg="center stretch"],
  [layout-align-lg="end stretch"],
  [layout-align-lg="space-between stretch"],
  [layout-align-lg="space-around stretch"] {
    align-items: stretch;
    align-content: stretch; }
  [flex-lg] {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (min-width: 960px) and (max-width: 1279px) {
  [flex-lg] {
    flex: 1 1 0%; } }

@media (min-width: 960px) and (max-width: 1279px) {
  [flex-lg-grow] {
    flex: 1 1 100%;
    box-sizing: border-box; }
  [flex-lg-initial] {
    flex: 0 1 auto;
    box-sizing: border-box; }
  [flex-lg-auto] {
    flex: 1 1 auto;
    box-sizing: border-box; }
  [flex-lg-none] {
    flex: 0 0 auto;
    box-sizing: border-box; }
  [flex-lg-noshrink] {
    flex: 1 0 auto;
    box-sizing: border-box; }
  [flex-lg-nogrow] {
    flex: 0 1 auto;
    box-sizing: border-box; }
  [flex-lg="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="0"],
  [layout-lg="row"] > [flex-lg="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="0"],
  [layout-lg="column"] > [flex-lg="0"] {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  [flex-lg="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="5"],
  [layout-lg="row"] > [flex-lg="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="5"],
  [layout-lg="column"] > [flex-lg="5"] {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  [flex-lg="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="10"],
  [layout-lg="row"] > [flex-lg="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="10"],
  [layout-lg="column"] > [flex-lg="10"] {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  [flex-lg="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="15"],
  [layout-lg="row"] > [flex-lg="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="15"],
  [layout-lg="column"] > [flex-lg="15"] {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  [flex-lg="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="20"],
  [layout-lg="row"] > [flex-lg="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="20"],
  [layout-lg="column"] > [flex-lg="20"] {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  [flex-lg="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="25"],
  [layout-lg="row"] > [flex-lg="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="25"],
  [layout-lg="column"] > [flex-lg="25"] {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  [flex-lg="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="30"],
  [layout-lg="row"] > [flex-lg="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="30"],
  [layout-lg="column"] > [flex-lg="30"] {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  [flex-lg="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="35"],
  [layout-lg="row"] > [flex-lg="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="35"],
  [layout-lg="column"] > [flex-lg="35"] {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  [flex-lg="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="40"],
  [layout-lg="row"] > [flex-lg="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="40"],
  [layout-lg="column"] > [flex-lg="40"] {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  [flex-lg="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="45"],
  [layout-lg="row"] > [flex-lg="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="45"],
  [layout-lg="column"] > [flex-lg="45"] {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  [flex-lg="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="50"],
  [layout-lg="row"] > [flex-lg="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="50"],
  [layout-lg="column"] > [flex-lg="50"] {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  [flex-lg="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="55"],
  [layout-lg="row"] > [flex-lg="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="55"],
  [layout-lg="column"] > [flex-lg="55"] {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  [flex-lg="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="60"],
  [layout-lg="row"] > [flex-lg="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="60"],
  [layout-lg="column"] > [flex-lg="60"] {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  [flex-lg="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="65"],
  [layout-lg="row"] > [flex-lg="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="65"],
  [layout-lg="column"] > [flex-lg="65"] {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  [flex-lg="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="70"],
  [layout-lg="row"] > [flex-lg="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="70"],
  [layout-lg="column"] > [flex-lg="70"] {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  [flex-lg="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="75"],
  [layout-lg="row"] > [flex-lg="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="75"],
  [layout-lg="column"] > [flex-lg="75"] {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  [flex-lg="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="80"],
  [layout-lg="row"] > [flex-lg="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="80"],
  [layout-lg="column"] > [flex-lg="80"] {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  [flex-lg="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="85"],
  [layout-lg="row"] > [flex-lg="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="85"],
  [layout-lg="column"] > [flex-lg="85"] {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  [flex-lg="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="90"],
  [layout-lg="row"] > [flex-lg="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="90"],
  [layout-lg="column"] > [flex-lg="90"] {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  [flex-lg="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="95"],
  [layout-lg="row"] > [flex-lg="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="95"],
  [layout-lg="column"] > [flex-lg="95"] {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  [flex-lg="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="100"],
  [layout-lg="row"] > [flex-lg="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="100"],
  [layout-lg="column"] > [flex-lg="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="33"], [layout="row"] > [flex-lg="33"], [layout-lg="row"] > [flex-lg="33"], [layout-lg="row"] > [flex-lg="33"] {
    flex: 1 1 33%;
    max-width: calc(100% / 3);
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="34"], [layout="row"] > [flex-lg="34"], [layout-lg="row"] > [flex-lg="34"], [layout-lg="row"] > [flex-lg="34"] {
    flex: 1 1 34%;
    max-width: 34%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="66"], [layout="row"] > [flex-lg="66"], [layout-lg="row"] > [flex-lg="66"], [layout-lg="row"] > [flex-lg="66"] {
    flex: 1 1 66%;
    max-width: calc(200% / 3);
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="67"], [layout="row"] > [flex-lg="67"], [layout-lg="row"] > [flex-lg="67"], [layout-lg="row"] > [flex-lg="67"] {
    flex: 1 1 67%;
    max-width: 67%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="33"], [layout="column"] > [flex-lg="33"], [layout-lg="column"] > [flex-lg="33"], [layout-lg="column"] > [flex-lg="33"] {
    flex: 1 1 33%;
    max-width: 100%;
    max-height: calc(100% / 3);
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="34"], [layout="column"] > [flex-lg="34"], [layout-lg="column"] > [flex-lg="34"], [layout-lg="column"] > [flex-lg="34"] {
    flex: 1 1 34%;
    max-width: 100%;
    max-height: 34%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="66"], [layout="column"] > [flex-lg="66"], [layout-lg="column"] > [flex-lg="66"], [layout-lg="column"] > [flex-lg="66"] {
    flex: 1 1 66%;
    max-width: 100%;
    max-height: calc(200% / 3);
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="67"], [layout="column"] > [flex-lg="67"], [layout-lg="column"] > [flex-lg="67"], [layout-lg="column"] > [flex-lg="67"] {
    flex: 1 1 67%;
    max-width: 100%;
    max-height: 67%;
    box-sizing: border-box; }
  [layout-lg], [layout-lg="column"], [layout-lg="row"] {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  [layout-lg="column"] {
    flex-direction: column; }
  [layout-lg="row"] {
    flex-direction: row; } }

@media (min-width: 1280px) {
  [flex-order-gt-lg="-20"] {
    order: -20; }
  [flex-order-gt-lg="-19"] {
    order: -19; }
  [flex-order-gt-lg="-18"] {
    order: -18; }
  [flex-order-gt-lg="-17"] {
    order: -17; }
  [flex-order-gt-lg="-16"] {
    order: -16; }
  [flex-order-gt-lg="-15"] {
    order: -15; }
  [flex-order-gt-lg="-14"] {
    order: -14; }
  [flex-order-gt-lg="-13"] {
    order: -13; }
  [flex-order-gt-lg="-12"] {
    order: -12; }
  [flex-order-gt-lg="-11"] {
    order: -11; }
  [flex-order-gt-lg="-10"] {
    order: -10; }
  [flex-order-gt-lg="-9"] {
    order: -9; }
  [flex-order-gt-lg="-8"] {
    order: -8; }
  [flex-order-gt-lg="-7"] {
    order: -7; }
  [flex-order-gt-lg="-6"] {
    order: -6; }
  [flex-order-gt-lg="-5"] {
    order: -5; }
  [flex-order-gt-lg="-4"] {
    order: -4; }
  [flex-order-gt-lg="-3"] {
    order: -3; }
  [flex-order-gt-lg="-2"] {
    order: -2; }
  [flex-order-gt-lg="-1"] {
    order: -1; }
  [flex-order-gt-lg="0"] {
    order: 0; }
  [flex-order-gt-lg="1"] {
    order: 1; }
  [flex-order-gt-lg="2"] {
    order: 2; }
  [flex-order-gt-lg="3"] {
    order: 3; }
  [flex-order-gt-lg="4"] {
    order: 4; }
  [flex-order-gt-lg="5"] {
    order: 5; }
  [flex-order-gt-lg="6"] {
    order: 6; }
  [flex-order-gt-lg="7"] {
    order: 7; }
  [flex-order-gt-lg="8"] {
    order: 8; }
  [flex-order-gt-lg="9"] {
    order: 9; }
  [flex-order-gt-lg="10"] {
    order: 10; }
  [flex-order-gt-lg="11"] {
    order: 11; }
  [flex-order-gt-lg="12"] {
    order: 12; }
  [flex-order-gt-lg="13"] {
    order: 13; }
  [flex-order-gt-lg="14"] {
    order: 14; }
  [flex-order-gt-lg="15"] {
    order: 15; }
  [flex-order-gt-lg="16"] {
    order: 16; }
  [flex-order-gt-lg="17"] {
    order: 17; }
  [flex-order-gt-lg="18"] {
    order: 18; }
  [flex-order-gt-lg="19"] {
    order: 19; }
  [flex-order-gt-lg="20"] {
    order: 20; }
  [flex-offset-gt-lg="0"] {
    margin-left: 0%; }
  [flex-offset-gt-lg="5"] {
    margin-left: 5%; }
  [flex-offset-gt-lg="10"] {
    margin-left: 10%; }
  [flex-offset-gt-lg="15"] {
    margin-left: 15%; }
  [flex-offset-gt-lg="20"] {
    margin-left: 20%; }
  [flex-offset-gt-lg="25"] {
    margin-left: 25%; }
  [flex-offset-gt-lg="30"] {
    margin-left: 30%; }
  [flex-offset-gt-lg="35"] {
    margin-left: 35%; }
  [flex-offset-gt-lg="40"] {
    margin-left: 40%; }
  [flex-offset-gt-lg="45"] {
    margin-left: 45%; }
  [flex-offset-gt-lg="50"] {
    margin-left: 50%; }
  [flex-offset-gt-lg="55"] {
    margin-left: 55%; }
  [flex-offset-gt-lg="60"] {
    margin-left: 60%; }
  [flex-offset-gt-lg="65"] {
    margin-left: 65%; }
  [flex-offset-gt-lg="70"] {
    margin-left: 70%; }
  [flex-offset-gt-lg="75"] {
    margin-left: 75%; }
  [flex-offset-gt-lg="80"] {
    margin-left: 80%; }
  [flex-offset-gt-lg="85"] {
    margin-left: 85%; }
  [flex-offset-gt-lg="90"] {
    margin-left: 90%; }
  [flex-offset-gt-lg="95"] {
    margin-left: 95%; }
  [flex-offset-gt-lg="33"] {
    margin-left: calc(100% / 3); }
  [flex-offset-gt-lg="66"] {
    margin-left: calc(200% / 3); }
  [layout-align-gt-lg],
  [layout-align-gt-lg="start stretch"] {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  [layout-align-gt-lg="start"],
  [layout-align-gt-lg="start start"],
  [layout-align-gt-lg="start center"],
  [layout-align-gt-lg="start end"],
  [layout-align-gt-lg="start stretch"] {
    justify-content: start; }
  [layout-align-gt-lg="center"],
  [layout-align-gt-lg="center start"],
  [layout-align-gt-lg="center center"],
  [layout-align-gt-lg="center end"],
  [layout-align-gt-lg="center stretch"] {
    justify-content: center; }
  [layout-align-gt-lg="end"],
  [layout-align-gt-lg="end center"],
  [layout-align-gt-lg="end start"],
  [layout-align-gt-lg="end end"],
  [layout-align-gt-lg="end stretch"] {
    justify-content: flex-end; }
  [layout-align-gt-lg="space-around"],
  [layout-align-gt-lg="space-around center"],
  [layout-align-gt-lg="space-around start"],
  [layout-align-gt-lg="space-around end"],
  [layout-align-gt-lg="space-around stretch"] {
    justify-content: space-around; }
  [layout-align-gt-lg="space-between"],
  [layout-align-gt-lg="space-between center"],
  [layout-align-gt-lg="space-between start"],
  [layout-align-gt-lg="space-between end"],
  [layout-align-gt-lg="space-between stretch"] {
    justify-content: space-between; }
  [layout-align-gt-lg="start start"],
  [layout-align-gt-lg="center start"],
  [layout-align-gt-lg="end start"],
  [layout-align-gt-lg="space-between start"],
  [layout-align-gt-lg="space-around start"] {
    align-items: flex-start;
    align-content: flex-start; }
  [layout-align-gt-lg="start center"],
  [layout-align-gt-lg="center center"],
  [layout-align-gt-lg="end center"],
  [layout-align-gt-lg="space-between center"],
  [layout-align-gt-lg="space-around center"] {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  [layout-align-gt-lg="start center"] > *,
  [layout-align-gt-lg="center center"] > *,
  [layout-align-gt-lg="end center"] > *,
  [layout-align-gt-lg="space-between center"] > *,
  [layout-align-gt-lg="space-around center"] > * {
    max-width: 100%;
    box-sizing: border-box; }
  [layout-align-gt-lg="start end"],
  [layout-align-gt-lg="center end"],
  [layout-align-gt-lg="end end"],
  [layout-align-gt-lg="space-between end"],
  [layout-align-gt-lg="space-around end"] {
    align-items: flex-end;
    align-content: flex-end; }
  [layout-align-gt-lg="start stretch"],
  [layout-align-gt-lg="center stretch"],
  [layout-align-gt-lg="end stretch"],
  [layout-align-gt-lg="space-between stretch"],
  [layout-align-gt-lg="space-around stretch"] {
    align-items: stretch;
    align-content: stretch; }
  [flex-gt-lg] {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (min-width: 1280px) {
  [flex-gt-lg] {
    flex: 1 1 0%; } }

@media (min-width: 1280px) {
  [flex-gt-lg-grow] {
    flex: 1 1 100%;
    box-sizing: border-box; }
  [flex-gt-lg-initial] {
    flex: 0 1 auto;
    box-sizing: border-box; }
  [flex-gt-lg-auto] {
    flex: 1 1 auto;
    box-sizing: border-box; }
  [flex-gt-lg-none] {
    flex: 0 0 auto;
    box-sizing: border-box; }
  [flex-gt-lg-noshrink] {
    flex: 1 0 auto;
    box-sizing: border-box; }
  [flex-gt-lg-nogrow] {
    flex: 0 1 auto;
    box-sizing: border-box; }
  [flex-gt-lg="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="0"],
  [layout-gt-lg="row"] > [flex-gt-lg="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="0"],
  [layout-gt-lg="column"] > [flex-gt-lg="0"] {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  [flex-gt-lg="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="5"],
  [layout-gt-lg="row"] > [flex-gt-lg="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="5"],
  [layout-gt-lg="column"] > [flex-gt-lg="5"] {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  [flex-gt-lg="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="10"],
  [layout-gt-lg="row"] > [flex-gt-lg="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="10"],
  [layout-gt-lg="column"] > [flex-gt-lg="10"] {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  [flex-gt-lg="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="15"],
  [layout-gt-lg="row"] > [flex-gt-lg="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="15"],
  [layout-gt-lg="column"] > [flex-gt-lg="15"] {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  [flex-gt-lg="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="20"],
  [layout-gt-lg="row"] > [flex-gt-lg="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="20"],
  [layout-gt-lg="column"] > [flex-gt-lg="20"] {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  [flex-gt-lg="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="25"],
  [layout-gt-lg="row"] > [flex-gt-lg="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="25"],
  [layout-gt-lg="column"] > [flex-gt-lg="25"] {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  [flex-gt-lg="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="30"],
  [layout-gt-lg="row"] > [flex-gt-lg="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="30"],
  [layout-gt-lg="column"] > [flex-gt-lg="30"] {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  [flex-gt-lg="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="35"],
  [layout-gt-lg="row"] > [flex-gt-lg="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="35"],
  [layout-gt-lg="column"] > [flex-gt-lg="35"] {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  [flex-gt-lg="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="40"],
  [layout-gt-lg="row"] > [flex-gt-lg="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="40"],
  [layout-gt-lg="column"] > [flex-gt-lg="40"] {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  [flex-gt-lg="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="45"],
  [layout-gt-lg="row"] > [flex-gt-lg="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="45"],
  [layout-gt-lg="column"] > [flex-gt-lg="45"] {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  [flex-gt-lg="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="50"],
  [layout-gt-lg="row"] > [flex-gt-lg="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="50"],
  [layout-gt-lg="column"] > [flex-gt-lg="50"] {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  [flex-gt-lg="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="55"],
  [layout-gt-lg="row"] > [flex-gt-lg="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="55"],
  [layout-gt-lg="column"] > [flex-gt-lg="55"] {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  [flex-gt-lg="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="60"],
  [layout-gt-lg="row"] > [flex-gt-lg="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="60"],
  [layout-gt-lg="column"] > [flex-gt-lg="60"] {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  [flex-gt-lg="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="65"],
  [layout-gt-lg="row"] > [flex-gt-lg="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="65"],
  [layout-gt-lg="column"] > [flex-gt-lg="65"] {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  [flex-gt-lg="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="70"],
  [layout-gt-lg="row"] > [flex-gt-lg="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="70"],
  [layout-gt-lg="column"] > [flex-gt-lg="70"] {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  [flex-gt-lg="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="75"],
  [layout-gt-lg="row"] > [flex-gt-lg="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="75"],
  [layout-gt-lg="column"] > [flex-gt-lg="75"] {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  [flex-gt-lg="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="80"],
  [layout-gt-lg="row"] > [flex-gt-lg="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="80"],
  [layout-gt-lg="column"] > [flex-gt-lg="80"] {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  [flex-gt-lg="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="85"],
  [layout-gt-lg="row"] > [flex-gt-lg="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="85"],
  [layout-gt-lg="column"] > [flex-gt-lg="85"] {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  [flex-gt-lg="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="90"],
  [layout-gt-lg="row"] > [flex-gt-lg="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="90"],
  [layout-gt-lg="column"] > [flex-gt-lg="90"] {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  [flex-gt-lg="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="95"],
  [layout-gt-lg="row"] > [flex-gt-lg="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="95"],
  [layout-gt-lg="column"] > [flex-gt-lg="95"] {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  [flex-gt-lg="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="100"],
  [layout-gt-lg="row"] > [flex-gt-lg="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="100"],
  [layout-gt-lg="column"] > [flex-gt-lg="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="33"], [layout="row"] > [flex-gt-lg="33"], [layout-gt-lg="row"] > [flex-gt-lg="33"], [layout-gt-lg="row"] > [flex-gt-lg="33"] {
    flex: 1 1 33%;
    max-width: calc(100% / 3);
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="34"], [layout="row"] > [flex-gt-lg="34"], [layout-gt-lg="row"] > [flex-gt-lg="34"], [layout-gt-lg="row"] > [flex-gt-lg="34"] {
    flex: 1 1 34%;
    max-width: 34%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="66"], [layout="row"] > [flex-gt-lg="66"], [layout-gt-lg="row"] > [flex-gt-lg="66"], [layout-gt-lg="row"] > [flex-gt-lg="66"] {
    flex: 1 1 66%;
    max-width: calc(200% / 3);
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="67"], [layout="row"] > [flex-gt-lg="67"], [layout-gt-lg="row"] > [flex-gt-lg="67"], [layout-gt-lg="row"] > [flex-gt-lg="67"] {
    flex: 1 1 67%;
    max-width: 67%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="33"], [layout="column"] > [flex-gt-lg="33"], [layout-gt-lg="column"] > [flex-gt-lg="33"], [layout-gt-lg="column"] > [flex-gt-lg="33"] {
    flex: 1 1 33%;
    max-width: 100%;
    max-height: calc(100% / 3);
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="34"], [layout="column"] > [flex-gt-lg="34"], [layout-gt-lg="column"] > [flex-gt-lg="34"], [layout-gt-lg="column"] > [flex-gt-lg="34"] {
    flex: 1 1 34%;
    max-width: 100%;
    max-height: 34%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="66"], [layout="column"] > [flex-gt-lg="66"], [layout-gt-lg="column"] > [flex-gt-lg="66"], [layout-gt-lg="column"] > [flex-gt-lg="66"] {
    flex: 1 1 66%;
    max-width: 100%;
    max-height: calc(200% / 3);
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="67"], [layout="column"] > [flex-gt-lg="67"], [layout-gt-lg="column"] > [flex-gt-lg="67"], [layout-gt-lg="column"] > [flex-gt-lg="67"] {
    flex: 1 1 67%;
    max-width: 100%;
    max-height: 67%;
    box-sizing: border-box; }
  [layout-gt-lg], [layout-gt-lg="column"], [layout-gt-lg="row"] {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  [layout-gt-lg="column"] {
    flex-direction: column; }
  [layout-gt-lg="row"] {
    flex-direction: row; }
  [flex-order-xl="-20"] {
    order: -20; }
  [flex-order-xl="-19"] {
    order: -19; }
  [flex-order-xl="-18"] {
    order: -18; }
  [flex-order-xl="-17"] {
    order: -17; }
  [flex-order-xl="-16"] {
    order: -16; }
  [flex-order-xl="-15"] {
    order: -15; }
  [flex-order-xl="-14"] {
    order: -14; }
  [flex-order-xl="-13"] {
    order: -13; }
  [flex-order-xl="-12"] {
    order: -12; }
  [flex-order-xl="-11"] {
    order: -11; }
  [flex-order-xl="-10"] {
    order: -10; }
  [flex-order-xl="-9"] {
    order: -9; }
  [flex-order-xl="-8"] {
    order: -8; }
  [flex-order-xl="-7"] {
    order: -7; }
  [flex-order-xl="-6"] {
    order: -6; }
  [flex-order-xl="-5"] {
    order: -5; }
  [flex-order-xl="-4"] {
    order: -4; }
  [flex-order-xl="-3"] {
    order: -3; }
  [flex-order-xl="-2"] {
    order: -2; }
  [flex-order-xl="-1"] {
    order: -1; }
  [flex-order-xl="0"] {
    order: 0; }
  [flex-order-xl="1"] {
    order: 1; }
  [flex-order-xl="2"] {
    order: 2; }
  [flex-order-xl="3"] {
    order: 3; }
  [flex-order-xl="4"] {
    order: 4; }
  [flex-order-xl="5"] {
    order: 5; }
  [flex-order-xl="6"] {
    order: 6; }
  [flex-order-xl="7"] {
    order: 7; }
  [flex-order-xl="8"] {
    order: 8; }
  [flex-order-xl="9"] {
    order: 9; }
  [flex-order-xl="10"] {
    order: 10; }
  [flex-order-xl="11"] {
    order: 11; }
  [flex-order-xl="12"] {
    order: 12; }
  [flex-order-xl="13"] {
    order: 13; }
  [flex-order-xl="14"] {
    order: 14; }
  [flex-order-xl="15"] {
    order: 15; }
  [flex-order-xl="16"] {
    order: 16; }
  [flex-order-xl="17"] {
    order: 17; }
  [flex-order-xl="18"] {
    order: 18; }
  [flex-order-xl="19"] {
    order: 19; }
  [flex-order-xl="20"] {
    order: 20; }
  [flex-offset-xl="0"] {
    margin-left: 0%; }
  [flex-offset-xl="5"] {
    margin-left: 5%; }
  [flex-offset-xl="10"] {
    margin-left: 10%; }
  [flex-offset-xl="15"] {
    margin-left: 15%; }
  [flex-offset-xl="20"] {
    margin-left: 20%; }
  [flex-offset-xl="25"] {
    margin-left: 25%; }
  [flex-offset-xl="30"] {
    margin-left: 30%; }
  [flex-offset-xl="35"] {
    margin-left: 35%; }
  [flex-offset-xl="40"] {
    margin-left: 40%; }
  [flex-offset-xl="45"] {
    margin-left: 45%; }
  [flex-offset-xl="50"] {
    margin-left: 50%; }
  [flex-offset-xl="55"] {
    margin-left: 55%; }
  [flex-offset-xl="60"] {
    margin-left: 60%; }
  [flex-offset-xl="65"] {
    margin-left: 65%; }
  [flex-offset-xl="70"] {
    margin-left: 70%; }
  [flex-offset-xl="75"] {
    margin-left: 75%; }
  [flex-offset-xl="80"] {
    margin-left: 80%; }
  [flex-offset-xl="85"] {
    margin-left: 85%; }
  [flex-offset-xl="90"] {
    margin-left: 90%; }
  [flex-offset-xl="95"] {
    margin-left: 95%; }
  [flex-offset-xl="33"] {
    margin-left: calc(100% / 3); }
  [flex-offset-xl="66"] {
    margin-left: calc(200% / 3); }
  [layout-align-xl],
  [layout-align-xl="start stretch"] {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  [layout-align-xl="start"],
  [layout-align-xl="start start"],
  [layout-align-xl="start center"],
  [layout-align-xl="start end"],
  [layout-align-xl="start stretch"] {
    justify-content: start; }
  [layout-align-xl="center"],
  [layout-align-xl="center start"],
  [layout-align-xl="center center"],
  [layout-align-xl="center end"],
  [layout-align-xl="center stretch"] {
    justify-content: center; }
  [layout-align-xl="end"],
  [layout-align-xl="end center"],
  [layout-align-xl="end start"],
  [layout-align-xl="end end"],
  [layout-align-xl="end stretch"] {
    justify-content: flex-end; }
  [layout-align-xl="space-around"],
  [layout-align-xl="space-around center"],
  [layout-align-xl="space-around start"],
  [layout-align-xl="space-around end"],
  [layout-align-xl="space-around stretch"] {
    justify-content: space-around; }
  [layout-align-xl="space-between"],
  [layout-align-xl="space-between center"],
  [layout-align-xl="space-between start"],
  [layout-align-xl="space-between end"],
  [layout-align-xl="space-between stretch"] {
    justify-content: space-between; }
  [layout-align-xl="start start"],
  [layout-align-xl="center start"],
  [layout-align-xl="end start"],
  [layout-align-xl="space-between start"],
  [layout-align-xl="space-around start"] {
    align-items: flex-start;
    align-content: flex-start; }
  [layout-align-xl="start center"],
  [layout-align-xl="center center"],
  [layout-align-xl="end center"],
  [layout-align-xl="space-between center"],
  [layout-align-xl="space-around center"] {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  [layout-align-xl="start center"] > *,
  [layout-align-xl="center center"] > *,
  [layout-align-xl="end center"] > *,
  [layout-align-xl="space-between center"] > *,
  [layout-align-xl="space-around center"] > * {
    max-width: 100%;
    box-sizing: border-box; }
  [layout-align-xl="start end"],
  [layout-align-xl="center end"],
  [layout-align-xl="end end"],
  [layout-align-xl="space-between end"],
  [layout-align-xl="space-around end"] {
    align-items: flex-end;
    align-content: flex-end; }
  [layout-align-xl="start stretch"],
  [layout-align-xl="center stretch"],
  [layout-align-xl="end stretch"],
  [layout-align-xl="space-between stretch"],
  [layout-align-xl="space-around stretch"] {
    align-items: stretch;
    align-content: stretch; }
  [flex-xl] {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (min-width: 1280px) {
  [flex-xl] {
    flex: 1 1 0%; } }

@media (min-width: 1280px) {
  [flex-xl-grow] {
    flex: 1 1 100%;
    box-sizing: border-box; }
  [flex-xl-initial] {
    flex: 0 1 auto;
    box-sizing: border-box; }
  [flex-xl-auto] {
    flex: 1 1 auto;
    box-sizing: border-box; }
  [flex-xl-none] {
    flex: 0 0 auto;
    box-sizing: border-box; }
  [flex-xl-noshrink] {
    flex: 1 0 auto;
    box-sizing: border-box; }
  [flex-xl-nogrow] {
    flex: 0 1 auto;
    box-sizing: border-box; }
  [flex-xl="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="0"],
  [layout-xl="row"] > [flex-xl="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="0"],
  [layout-xl="column"] > [flex-xl="0"] {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  [flex-xl="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="5"],
  [layout-xl="row"] > [flex-xl="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="5"],
  [layout-xl="column"] > [flex-xl="5"] {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  [flex-xl="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="10"],
  [layout-xl="row"] > [flex-xl="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="10"],
  [layout-xl="column"] > [flex-xl="10"] {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  [flex-xl="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="15"],
  [layout-xl="row"] > [flex-xl="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="15"],
  [layout-xl="column"] > [flex-xl="15"] {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  [flex-xl="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="20"],
  [layout-xl="row"] > [flex-xl="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="20"],
  [layout-xl="column"] > [flex-xl="20"] {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  [flex-xl="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="25"],
  [layout-xl="row"] > [flex-xl="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="25"],
  [layout-xl="column"] > [flex-xl="25"] {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  [flex-xl="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="30"],
  [layout-xl="row"] > [flex-xl="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="30"],
  [layout-xl="column"] > [flex-xl="30"] {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  [flex-xl="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="35"],
  [layout-xl="row"] > [flex-xl="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="35"],
  [layout-xl="column"] > [flex-xl="35"] {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  [flex-xl="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="40"],
  [layout-xl="row"] > [flex-xl="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="40"],
  [layout-xl="column"] > [flex-xl="40"] {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  [flex-xl="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="45"],
  [layout-xl="row"] > [flex-xl="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="45"],
  [layout-xl="column"] > [flex-xl="45"] {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  [flex-xl="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="50"],
  [layout-xl="row"] > [flex-xl="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="50"],
  [layout-xl="column"] > [flex-xl="50"] {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  [flex-xl="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="55"],
  [layout-xl="row"] > [flex-xl="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="55"],
  [layout-xl="column"] > [flex-xl="55"] {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  [flex-xl="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="60"],
  [layout-xl="row"] > [flex-xl="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="60"],
  [layout-xl="column"] > [flex-xl="60"] {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  [flex-xl="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="65"],
  [layout-xl="row"] > [flex-xl="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="65"],
  [layout-xl="column"] > [flex-xl="65"] {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  [flex-xl="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="70"],
  [layout-xl="row"] > [flex-xl="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="70"],
  [layout-xl="column"] > [flex-xl="70"] {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  [flex-xl="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="75"],
  [layout-xl="row"] > [flex-xl="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="75"],
  [layout-xl="column"] > [flex-xl="75"] {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  [flex-xl="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="80"],
  [layout-xl="row"] > [flex-xl="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="80"],
  [layout-xl="column"] > [flex-xl="80"] {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  [flex-xl="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="85"],
  [layout-xl="row"] > [flex-xl="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="85"],
  [layout-xl="column"] > [flex-xl="85"] {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  [flex-xl="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="90"],
  [layout-xl="row"] > [flex-xl="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="90"],
  [layout-xl="column"] > [flex-xl="90"] {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  [flex-xl="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="95"],
  [layout-xl="row"] > [flex-xl="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="95"],
  [layout-xl="column"] > [flex-xl="95"] {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  [flex-xl="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="100"],
  [layout-xl="row"] > [flex-xl="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="100"],
  [layout-xl="column"] > [flex-xl="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="33"], [layout="row"] > [flex-xl="33"], [layout-xl="row"] > [flex-xl="33"], [layout-xl="row"] > [flex-xl="33"] {
    flex: 1 1 33%;
    max-width: calc(100% / 3);
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="34"], [layout="row"] > [flex-xl="34"], [layout-xl="row"] > [flex-xl="34"], [layout-xl="row"] > [flex-xl="34"] {
    flex: 1 1 34%;
    max-width: 34%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="66"], [layout="row"] > [flex-xl="66"], [layout-xl="row"] > [flex-xl="66"], [layout-xl="row"] > [flex-xl="66"] {
    flex: 1 1 66%;
    max-width: calc(200% / 3);
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="67"], [layout="row"] > [flex-xl="67"], [layout-xl="row"] > [flex-xl="67"], [layout-xl="row"] > [flex-xl="67"] {
    flex: 1 1 67%;
    max-width: 67%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="33"], [layout="column"] > [flex-xl="33"], [layout-xl="column"] > [flex-xl="33"], [layout-xl="column"] > [flex-xl="33"] {
    flex: 1 1 33%;
    max-width: 100%;
    max-height: calc(100% / 3);
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="34"], [layout="column"] > [flex-xl="34"], [layout-xl="column"] > [flex-xl="34"], [layout-xl="column"] > [flex-xl="34"] {
    flex: 1 1 34%;
    max-width: 100%;
    max-height: 34%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="66"], [layout="column"] > [flex-xl="66"], [layout-xl="column"] > [flex-xl="66"], [layout-xl="column"] > [flex-xl="66"] {
    flex: 1 1 66%;
    max-width: 100%;
    max-height: calc(200% / 3);
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="67"], [layout="column"] > [flex-xl="67"], [layout-xl="column"] > [flex-xl="67"], [layout-xl="column"] > [flex-xl="67"] {
    flex: 1 1 67%;
    max-width: 100%;
    max-height: 67%;
    box-sizing: border-box; }
  [layout-xl], [layout-xl="column"], [layout-xl="row"] {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  [layout-xl="column"] {
    flex-direction: column; }
  [layout-xl="row"] {
    flex-direction: row; }
  [hide]:not([show-gt-xs]):not([show-gt-sm]):not([show-gt-md]):not([show-gt-lg]):not([show-xl]):not([show]), [hide-gt-xs]:not([show-gt-xs]):not([show-gt-sm]):not([show-gt-md]):not([show-gt-lg]):not([show-xl]):not([show]), [hide-gt-sm]:not([show-gt-xs]):not([show-gt-sm]):not([show-gt-md]):not([show-gt-lg]):not([show-xl]):not([show]), [hide-gt-md]:not([show-gt-xs]):not([show-gt-sm]):not([show-gt-md]):not([show-gt-lg]):not([show-xl]):not([show]), [hide-gt-lg]:not([show-gt-xs]):not([show-gt-sm]):not([show-gt-md]):not([show-gt-lg]):not([show-xl]):not([show]) {
    display: none; }
  [hide-xl]:not([show-xl]):not([show-gt-lg]):not([show]) {
    display: none; } }

.md-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  transition: opacity 450ms;
  opacity: 0;
  z-index: 50;
  pointer-events: none; }
  .md-backdrop.md-backdrop-absolute {
    position: absolute; }
  .md-backdrop.md-active {
    opacity: 1;
    pointer-events: all; }
  .md-backdrop.md-select-backdrop {
    z-index: 81;
    transition-duration: 0; }
  .md-backdrop.md-dialog-backdrop {
    z-index: 79; }
  .md-backdrop.md-bottom-sheet-backdrop {
    z-index: 69; }
  .md-backdrop.md-sidenav-backdrop {
    z-index: 59; }
  .md-backdrop.md-opaque.md-active {
    opacity: .48; }

.md-backdrop {
  background-color: rgba(33, 33, 33, 0); }
  .md-backdrop.md-opaque {
    background-color: #212121; }
    .md-backdrop.md-opaque.md-active {
      opacity: .48; }

md-content {
  display: block;
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }
  md-content[md-scroll-y] {
    overflow-y: auto;
    overflow-x: hidden; }
  md-content[md-scroll-x] {
    overflow-x: auto;
    overflow-y: hidden; }
  md-content.autoScroll {
    -webkit-overflow-scrolling: auto; }

md-content {
  color: rgba(0, 0, 0, 0.87);
  background-color: white; }

md-data-table, .md-data-table {
  display: table;
  border-spacing: 0;
  border-collapse: collapse;
  font-size: 13px;
  box-sizing: border-box;
  width: 100%; }
  md-data-table md-checkbox, .md-data-table md-checkbox {
    margin: 0;
    width: 18px;
    vertical-align: middle; }
  md-data-table tr, .md-data-table tr {
    vertical-align: middle; }
    md-data-table tr:last-child, .md-data-table tr:last-child {
      height: 56px; }
  md-data-table th, md-data-table td, .md-data-table th, .md-data-table td {
    padding: 0 32px 0 24px;
    text-align: right; }
    md-data-table th:first-of-type, md-data-table td:first-of-type, .md-data-table th:first-of-type, .md-data-table td:first-of-type {
      padding-left: 24px; }
    md-data-table th:last-of-type, md-data-table td:last-of-type, .md-data-table th:last-of-type, .md-data-table td:last-of-type {
      padding-right: 24px; }
    md-data-table th.md-text-cell, md-data-table td.md-text-cell, .md-data-table th.md-text-cell, .md-data-table td.md-text-cell {
      text-align: left; }
    md-data-table th.md-data-check-cell, md-data-table td.md-data-check-cell, .md-data-table th.md-data-check-cell, .md-data-table td.md-data-check-cell {
      padding: 0 0 0 24px;
      width: 18px; }
  md-data-table th, .md-data-table th {
    font-size: 12px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.54);
    text-overflow: ellipsis;
    box-sizing: border-box;
    /*
     TODO (ollwenjones) sorting functionality pending, but these class names
     were tested, and can be used outside of the library component structures.
     */ }
    md-data-table th.sortable, .md-data-table th.sortable {
      cursor: pointer; }
    md-data-table th.sorted-ascending, md-data-table th.sorted-descending, .md-data-table th.sorted-ascending, .md-data-table th.sorted-descending {
      color: rgba(0, 0, 0, 0.87); }
      md-data-table th.sorted-ascending:before, md-data-table th.sorted-descending:before, .md-data-table th.sorted-ascending:before, .md-data-table th.sorted-descending:before {
        font-family: 'Material Icons';
        font-size: 16px;
        content: "\e5d8";
        margin-right: 5px;
        vertical-align: sub; }
      md-data-table th.sorted-ascending:hover, md-data-table th.sorted-descending:hover, .md-data-table th.sorted-ascending:hover, .md-data-table th.sorted-descending:hover {
        cursor: pointer; }
        md-data-table th.sorted-ascending:hover:before, md-data-table th.sorted-descending:hover:before, .md-data-table th.sorted-ascending:hover:before, .md-data-table th.sorted-descending:hover:before {
          color: rgba(0, 0, 0, 0.38); }
    md-data-table th.sorted-descending:before, .md-data-table th.sorted-descending:before {
      content: "\e5db"; }
  md-data-table td, .md-data-table td {
    position: relative;
    vertical-align: middle;
    height: 48px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.87); }
  md-data-table th:first-child, md-data-table td:first-child, .md-data-table th:first-child, .md-data-table td:first-child {
    padding-left: 24px; }
  md-data-table th:last-child, md-data-table td:last-child, .md-data-table th:last-child, .md-data-table td:last-child {
    padding-right: 24px; }
  md-data-table tr, .md-data-table tr {
    position: relative;
    height: 48px; }
  md-data-table tbody tr.selected, md-data-table tbody tr.active, md-data-table .md-data-tbody tr.selected, md-data-table .md-data-tbody tr.active, .md-data-table tbody tr.selected, .md-data-table tbody tr.active, .md-data-table .md-data-tbody tr.selected, .md-data-table .md-data-tbody tr.active {
    background-color: whitesmoke; }
  md-data-table tbody tr:hover, md-data-table .md-data-tbody tr:hover, .md-data-table tbody tr:hover, .md-data-table .md-data-tbody tr:hover {
    background-color: #eeeeee; }

html, body {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/************
 * Headings
 ************/
.md-display-4 {
  font-size: 112px;
  font-weight: 300;
  letter-spacing: -0.010em;
  line-height: 112px; }

.md-display-3 {
  font-size: 56px;
  font-weight: 400;
  letter-spacing: -0.005em;
  line-height: 56px; }

.md-display-2 {
  font-size: 45px;
  font-weight: 400;
  line-height: 64px; }

.md-display-1 {
  font-size: 34px;
  font-weight: 400;
  line-height: 40px; }

.md-headline, .md-dialog md-dialog-title {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px; }

.md-title {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.005em; }

.md-subhead {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.010em;
  line-height: 24px; }

/************
 * Body Copy
 ************/
.md-body-1 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.010em;
  line-height: 20px; }

.md-body-2 {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.010em;
  line-height: 24px; }

.md-caption {
  font-size: 12px;
  letter-spacing: 0.020em; }

.md-button {
  letter-spacing: 0.010em; }

/************
 * Defaults
 ************/
button,
select,
html,
textarea,
input {
  font-family: RobotoDraft, Roboto, "Helvetica Neue", sans-serif; }

select,
button,
textarea,
input {
  font-size: 100%; }

md-dialog {
  display: none; }

.md-dialog {
  min-width: 300px;
  min-height: 100px;
  padding: 24px;
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: scale(0.2);
  order: 1;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }
  .md-dialog:focus {
    outline: none; }
  .md-dialog.md-active {
    opacity: 1;
    transform: perspective(1px) scale(1); }

.md-dialog {
  border-radius: 4px;
  background-color: white; }
  .md-dialog.md-content-overflow .md-actions, .md-dialog.md-content-overflow md-dialog-actions {
    border-top-color: rgba(0, 0, 0, 0.12); }

.md-dialog md-dialog-actions {
  display: flex;
  order: 2;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-end;
  padding-top: 24px;
  padding-right: 8px;
  padding-left: 16px;
  margin-bottom: -24px;
  margin-left: -24px;
  margin-right: -24px;
  right: -24px;
  min-height: 52px;
  overflow: hidden; }
  .md-dialog md-dialog-actions [md-button], .md-dialog md-dialog-actions [md-raised-button] {
    margin-bottom: 8px;
    margin-left: 8px;
    margin-right: 0;
    margin-top: 8px; }

html, body {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/************
 * Headings
 ************/
.md-display-4 {
  font-size: 112px;
  font-weight: 300;
  letter-spacing: -0.010em;
  line-height: 112px; }

.md-display-3 {
  font-size: 56px;
  font-weight: 400;
  letter-spacing: -0.005em;
  line-height: 56px; }

.md-display-2 {
  font-size: 45px;
  font-weight: 400;
  line-height: 64px; }

.md-display-1 {
  font-size: 34px;
  font-weight: 400;
  line-height: 40px; }

.md-headline, .md-dialog md-dialog-title {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px; }

.md-title {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.005em; }

.md-subhead {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.010em;
  line-height: 24px; }

/************
 * Body Copy
 ************/
.md-body-1 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.010em;
  line-height: 20px; }

.md-body-2 {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.010em;
  line-height: 24px; }

.md-caption {
  font-size: 12px;
  letter-spacing: 0.020em; }

.md-button {
  letter-spacing: 0.010em; }

/************
 * Defaults
 ************/
button,
select,
html,
textarea,
input {
  font-family: RobotoDraft, Roboto, "Helvetica Neue", sans-serif; }

select,
button,
textarea,
input {
  font-size: 100%; }

.md-dialog md-dialog-title {
  margin-bottom: 20px; }

md-divider {
  display: block;
  border-top-width: 1px;
  border-top-style: solid;
  margin: 0; }
  md-divider[md-inset] {
    margin-left: 80px; }

.layout-row > md-divider {
  border-top-width: 0;
  border-right-width: 1px;
  border-right-style: solid; }

md-divider {
  border-top-color: rgba(0, 0, 0, 0.12); }

.layout-row > md-divider {
  border-right-color: rgba(0, 0, 0, 0.12); }

md-list {
  display: block;
  padding: 8px 0px 8px 0px; }
  md-list .md-subheader {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.010em;
    line-height: 1.2em; }

md-list-item {
  position: relative; }
  md-list-item.md-proxy-focus.md-focused .md-no-style {
    transition: background-color 0.15s linear; }
  md-list-item.md-no-proxy,
  md-list-item .md-no-style {
    position: relative;
    padding: 0px 16px;
    flex: 1 1 auto; }
    md-list-item.md-no-proxy.md-button,
    md-list-item .md-no-style.md-button {
      font-size: inherit;
      height: inherit;
      text-align: left;
      text-transform: none;
      width: 100%;
      white-space: normal;
      flex-direction: inherit;
      align-items: inherit;
      border-radius: 0; }
      md-list-item.md-no-proxy.md-button > .md-ripple-container,
      md-list-item .md-no-style.md-button > .md-ripple-container {
        border-radius: 0; }
    md-list-item.md-no-proxy:focus,
    md-list-item .md-no-style:focus {
      outline: none; }
  md-list-item.md-with-secondary {
    position: relative; }
  md-list-item.md-clickable:hover {
    cursor: pointer; }
  md-list-item md-divider {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; }
    md-list-item md-divider[md-inset] {
      left: 96px;
      width: calc(100% - 96px);
      margin: 0; }

md-list-item, md-list-item .md-list-item-inner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 48px;
  height: auto; }
  md-list-item > div.md-primary > [md-icon]:not(.md-avatar-icon),
  md-list-item > div.md-secondary > [md-icon]:not(.md-avatar-icon),
  md-list-item > [md-icon]:first-child:not(.md-avatar-icon),
  md-list-item > [md-icon].md-secondary:not(.md-avatar-icon), md-list-item .md-list-item-inner > div.md-primary > [md-icon]:not(.md-avatar-icon),
  md-list-item .md-list-item-inner > div.md-secondary > [md-icon]:not(.md-avatar-icon),
  md-list-item .md-list-item-inner > [md-icon]:first-child:not(.md-avatar-icon),
  md-list-item .md-list-item-inner > [md-icon].md-secondary:not(.md-avatar-icon) {
    width: 24px;
    margin-top: 16px;
    margin-bottom: 12px;
    box-sizing: content-box; }
  md-list-item > div.md-primary > md-checkbox,
  md-list-item > div.md-secondary > md-checkbox,
  md-list-item > md-checkbox,
  md-list-item md-checkbox.md-secondary, md-list-item .md-list-item-inner > div.md-primary > md-checkbox,
  md-list-item .md-list-item-inner > div.md-secondary > md-checkbox,
  md-list-item .md-list-item-inner > md-checkbox,
  md-list-item .md-list-item-inner md-checkbox.md-secondary {
    align-self: center; }
    md-list-item > div.md-primary > md-checkbox .md-label,
    md-list-item > div.md-secondary > md-checkbox .md-label,
    md-list-item > md-checkbox .md-label,
    md-list-item md-checkbox.md-secondary .md-label, md-list-item .md-list-item-inner > div.md-primary > md-checkbox .md-label,
    md-list-item .md-list-item-inner > div.md-secondary > md-checkbox .md-label,
    md-list-item .md-list-item-inner > md-checkbox .md-label,
    md-list-item .md-list-item-inner md-checkbox.md-secondary .md-label {
      display: none; }
  md-list-item > [md-icon]:first-child:not(.md-avatar-icon), md-list-item .md-list-item-inner > [md-icon]:first-child:not(.md-avatar-icon) {
    margin-right: 32px; }
  md-list-item > md-checkbox, md-list-item .md-list-item-inner > md-checkbox {
    width: 24px;
    margin-left: 3px;
    margin-right: 29px;
    margin-top: 16px; }
  md-list-item .md-avatar, md-list-item .md-avatar-icon, md-list-item .md-list-item-inner .md-avatar, md-list-item .md-list-item-inner .md-avatar-icon {
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 16px;
    border-radius: 50%;
    box-sizing: content-box; }
  md-list-item .md-avatar, md-list-item .md-list-item-inner .md-avatar {
    width: 40px;
    height: 40px; }
  md-list-item .md-avatar-icon, md-list-item .md-list-item-inner .md-avatar-icon {
    padding: 8px; }
  md-list-item md-checkbox.md-secondary,
  md-list-item md-switch.md-secondary, md-list-item .md-list-item-inner md-checkbox.md-secondary,
  md-list-item .md-list-item-inner md-switch.md-secondary {
    margin-top: 0;
    margin-bottom: 0; }
  md-list-item md-checkbox.md-secondary, md-list-item .md-list-item-inner md-checkbox.md-secondary {
    margin-right: 0; }
  md-list-item md-switch.md-secondary, md-list-item .md-list-item-inner md-switch.md-secondary {
    margin-right: -6px; }
  md-list-item button.md-button.md-secondary-container, md-list-item .md-list-item-inner button.md-button.md-secondary-container {
    background-color: transparent;
    align-self: center;
    border-radius: 50%;
    margin: 0px;
    min-width: 0px; }
    md-list-item button.md-button.md-secondary-container .md-ripple,
    md-list-item button.md-button.md-secondary-container .md-ripple-container, md-list-item .md-list-item-inner button.md-button.md-secondary-container .md-ripple,
    md-list-item .md-list-item-inner button.md-button.md-secondary-container .md-ripple-container {
      border-radius: 50%; }
    md-list-item button.md-button.md-secondary-container.md-icon-button, md-list-item .md-list-item-inner button.md-button.md-secondary-container.md-icon-button {
      margin-right: -12px; }
  md-list-item .md-secondary-container,
  md-list-item .md-secondary, md-list-item .md-list-item-inner .md-secondary-container,
  md-list-item .md-list-item-inner .md-secondary {
    position: absolute;
    top: 50%;
    right: 16px;
    margin: 0 0 0 16px;
    transform: translate3d(0, -50%, 0); }
  md-list-item > .md-button.md-secondary-container > .md-secondary, md-list-item .md-list-item-inner > .md-button.md-secondary-container > .md-secondary {
    margin-left: 0;
    position: static; }
  md-list-item > p, md-list-item > .md-list-item-inner > p, md-list-item .md-list-item-inner > p, md-list-item .md-list-item-inner > .md-list-item-inner > p {
    flex: 1;
    margin: 0; }

md-list-item.md-2-line,
md-list-item.md-2-line > .md-no-style,
md-list-item.md-3-line,
md-list-item.md-3-line > .md-no-style {
  align-items: flex-start;
  justify-content: center; }
  md-list-item.md-2-line .md-list-item-text,
  md-list-item.md-2-line > .md-no-style .md-list-item-text,
  md-list-item.md-3-line .md-list-item-text,
  md-list-item.md-3-line > .md-no-style .md-list-item-text {
    flex: 1;
    margin: auto;
    text-overflow: ellipsis; }
    md-list-item.md-2-line .md-list-item-text.md-offset,
    md-list-item.md-2-line > .md-no-style .md-list-item-text.md-offset,
    md-list-item.md-3-line .md-list-item-text.md-offset,
    md-list-item.md-3-line > .md-no-style .md-list-item-text.md-offset {
      margin-left: 56px; }
    md-list-item.md-2-line .md-list-item-text h3,
    md-list-item.md-2-line > .md-no-style .md-list-item-text h3,
    md-list-item.md-3-line .md-list-item-text h3,
    md-list-item.md-3-line > .md-no-style .md-list-item-text h3 {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.010em;
      margin: 0 0 0px 0;
      line-height: 1.2em;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    md-list-item.md-2-line .md-list-item-text h4,
    md-list-item.md-2-line > .md-no-style .md-list-item-text h4,
    md-list-item.md-3-line .md-list-item-text h4,
    md-list-item.md-3-line > .md-no-style .md-list-item-text h4 {
      font-size: 14px;
      letter-spacing: 0.010em;
      margin: 3px 0 1px 0;
      font-weight: 400;
      line-height: 1.2em;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    md-list-item.md-2-line .md-list-item-text p,
    md-list-item.md-2-line > .md-no-style .md-list-item-text p,
    md-list-item.md-3-line .md-list-item-text p,
    md-list-item.md-3-line > .md-no-style .md-list-item-text p {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.010em;
      margin: 0 0 0 0;
      line-height: 1.6em; }

md-list-item.md-2-line,
md-list-item.md-2-line > .md-no-style {
  height: auto;
  min-height: 72px; }
  md-list-item.md-2-line.md-long-text,
  md-list-item.md-2-line > .md-no-style.md-long-text {
    margin: 1.6em; }
  md-list-item.md-2-line > .md-avatar, md-list-item.md-2-line .md-avatar-icon,
  md-list-item.md-2-line > .md-no-style > .md-avatar,
  md-list-item.md-2-line > .md-no-style .md-avatar-icon {
    margin-top: 12px; }
  md-list-item.md-2-line > [md-icon]:first-child,
  md-list-item.md-2-line > .md-no-style > [md-icon]:first-child {
    align-self: flex-start; }
  md-list-item.md-2-line .md-list-item-text,
  md-list-item.md-2-line > .md-no-style .md-list-item-text {
    flex: 1; }

md-list-item.md-3-line,
md-list-item.md-3-line > .md-no-style {
  height: auto;
  min-height: 88px; }
  md-list-item.md-3-line.md-long-text,
  md-list-item.md-3-line > .md-no-style.md-long-text {
    margin: 1.6em; }
  md-list-item.md-3-line > [md-icon]:first-child,
  md-list-item.md-3-line > .md-avatar,
  md-list-item.md-3-line > .md-no-style > [md-icon]:first-child,
  md-list-item.md-3-line > .md-no-style > .md-avatar {
    margin-top: 16px; }

md-list md-list-item.md-2-line .md-list-item-text h3, md-list md-list-item.md-2-line .md-list-item-text h4,
md-list md-list-item.md-3-line .md-list-item-text h3,
md-list md-list-item.md-3-line .md-list-item-text h4 {
  color: rgba(0, 0, 0, 0.87); }

md-list md-list-item.md-2-line .md-list-item-text p,
md-list md-list-item.md-3-line .md-list-item-text p {
  color: rgba(0, 0, 0, 0.54); }

md-list .md-proxy-focus.md-focused div.md-no-style {
  background-color: white; }

md-list md-list-item > .md-list-item-inner > [md-icon] {
  color: rgba(0, 0, 0, 0.54); }
  md-list md-list-item > .md-list-item-inner > [md-icon].md-highlight {
    color: #009688; }
    md-list md-list-item > .md-list-item-inner > [md-icon].md-highlight.md-accent {
      color: #9c27b0; }

md-list md-list-item > .md-list-item-inner > .md-avatar-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: whitesmoke; }

md-pagination, .md-pagination {
  display: block;
  padding: 14px 0;
  min-height: 28px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87);
  text-align: right; }
  md-pagination md-pagination-controls, md-pagination .md-pagination-controls, .md-pagination md-pagination-controls, .md-pagination .md-pagination-controls {
    margin: 0 6px; }
  md-pagination .md-pagination-control, .md-pagination .md-pagination-control {
    display: inline-block;
    margin: 0 6px;
    width: 36px;
    height: 28px;
    vertical-align: middle; }
    md-pagination .md-pagination-control button, .md-pagination .md-pagination-control button {
      display: none;
      width: 36px;
      height: 28px;
      border: 0;
      outline: 0;
      background: transparent; }
  md-pagination .md-pagination-control-active button, .md-pagination .md-pagination-control-active button {
    display: block; }
  md-pagination md-pagination-range, md-pagination .md-pagination-range, .md-pagination md-pagination-range, .md-pagination .md-pagination-range {
    margin: 0 20px; }
  md-pagination md-pagination-items-per-page, md-pagination .md-pagination-items-per-page, .md-pagination md-pagination-items-per-page, .md-pagination .md-pagination-items-per-page {
    margin: 0 6px; }
  md-pagination .md-pagination-length-select, .md-pagination .md-pagination-length-select {
    margin: 0 6px; }
  md-pagination .md-pagination-items-per-page-label, .md-pagination .md-pagination-items-per-page-label {
    margin: 0 6px;
    font-size: 11px; }

[md-peekaboo][breakAction=show] {
  display: none; }
  [md-peekaboo][breakAction=show].md-peekaboo-active {
    display: inherit; }

[md-peekaboo][breakAction=hide] {
  display: inherit; }
  [md-peekaboo][breakAction=hide].md-peekaboo-active {
    display: none; }

/**
 * Mixin that creates a new stacking context.
 * see https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context
 */
.md-subheader {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1em;
  margin: 0 0 0 0;
  position: relative; }
  .md-subheader .md-subheader-inner {
    display: block;
    padding: 16px; }
  .md-subheader .md-subheader-content {
    display: block;
    z-index: 1;
    position: relative; }

.md-subheader {
  color: rgba(0, 0, 0, 0.54);
  background-color: white; }
  .md-subheader.md-primary {
    color: #009688; }
  .md-subheader.md-accent {
    color: #9c27b0; }
  .md-subheader.md-warn {
    color: #f44336; }

md-switch {
  display: flex;
  align-items: center;
  margin: 15px;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  user-select: none; }
  md-switch * {
    box-sizing: border-box; }
  md-switch .md-switch-container {
    cursor: grab;
    width: 36px;
    height: 24px;
    position: relative;
    user-select: none;
    margin-right: 8px; }
  md-switch:not([disabled]) .md-switch-dragging,
  md-switch:not([disabled]).md-switch-dragging .md-switch-container {
    cursor: grabbing; }
  md-switch .md-switch-label {
    border: 0 transparent; }
  md-switch .md-switch-bar {
    left: 1px;
    width: 34px;
    top: 5px;
    height: 14px;
    border-radius: 8px;
    position: absolute; }
  md-switch .md-switch-thumb-container {
    top: 2px;
    left: 0;
    width: 16px;
    position: absolute;
    transform: translate3d(0, 0, 0);
    z-index: 1; }
  md-switch[aria-checked="true"] .md-switch-thumb-container {
    transform: translate3d(100%, 0, 0); }
  md-switch .md-switch-thumb {
    position: absolute;
    margin: 0;
    left: 0;
    top: 0;
    outline: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.26); }
    md-switch .md-switch-thumb .md-ripple-container {
      position: absolute;
      display: block;
      width: auto;
      height: auto;
      left: -20px;
      top: -20px;
      right: -20px;
      bottom: -20px; }
  md-switch:not(.md-switch-dragging) .md-switch-bar,
  md-switch:not(.md-switch-dragging) .md-switch-thumb-container,
  md-switch:not(.md-switch-dragging) .md-switch-thumb {
    transition: all 0.08s linear;
    transition-property: transform, background-color; }
  md-switch:not(.md-switch-dragging) .md-switch-bar,
  md-switch:not(.md-switch-dragging) .md-switch-thumb {
    transition-delay: 0.05s; }

@media screen and (-ms-high-contrast: active) {
  md-switch .md-switch-bar {
    background-color: #666; }
  md-switch[aria-checked="true"] .md-switch-bar {
    background-color: #9E9E9E; }
  md-switch.md-default-theme .md-switch-thumb {
    background-color: #fff; } }

md-switch .md-switch-thumb {
  background-color: #fafafa; }

md-switch .md-switch-bar {
  background-color: #9e9e9e; }

md-switch[aria-checked="true"] .md-switch-thumb {
  background-color: #9c27b0; }

md-switch[aria-checked="true"] .md-switch-bar {
  background-color: rgba(156, 39, 176, 0.5); }

md-switch[aria-checked="true"].md-primary .md-switch-thumb {
  background-color: #009688; }

md-switch[aria-checked="true"].md-primary .md-switch-bar {
  background-color: rgba(0, 150, 136, 0.5); }

md-switch[aria-checked="true"].md-warn .md-switch-thumb {
  background-color: #f44336; }

md-switch[aria-checked="true"].md-warn .md-switch-bar {
  background-color: rgba(244, 67, 54, 0.5); }

md-switch[disabled] .md-switch-thumb {
  background-color: #bdbdbd; }

md-switch[disabled] .md-switch-bar {
  background-color: rgba(0, 0, 0, 0.12); }

md-switch:focus .md-switch-label:not(:empty) {
  border: 1px dotted rgba(0, 0, 0, 0.87); }
